import moment from 'moment';
import { defaultTo, subtract } from 'ramda';

import { getLastAdded } from '../../actions/release';
import { getReleaseDates } from '../../actions/calendar';
import { defaultPagination } from './common';

const lastAddedNeeds = [
  (params) => {
    return getLastAdded({
      offset: defaultPagination.rows * defaultPagination.maxCols * subtract(parseInt(defaultTo(1, params.page), 10), 1),
      limit: defaultPagination.rows * defaultPagination.maxCols,
      ...(params.year && params.month && params.day ? {
        date: moment(`${params.year}-${params.month}-${params.day}`, 'YYYY-M-D').format('YYYY-MM-DD'),
      } : {}),
    });
  },
  () => getReleaseDates(),
];

export default {
  lastAddedNeeds,
};

export {
  lastAddedNeeds,
};

/**
 * Root Reducer
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user, { auth, signup } from './user';
import releases from './releases';
import app from './app';
import tracks from './track';
import { artistList } from './artist';
import reviews from './review';
import { genres } from './genre';
import { series } from './series';
import shoppingCart from './sc';
import search from './search';
import { feedbackList } from './feedback';
import releaseDates from './releaseCalendar';
import { notificationList } from './notifications';
import { messageList } from './message';
import validToken from './reset-password';
import player from './player';
import musicRequests from './musicRequests';
import { paymentList } from './payments';
import directPayment from './directPayment';

export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  auth,
  signup,
  releases,
  tracks,
  artistList,
  reviews,
  genres,
  series,
  app,
  shoppingCart,
  search,
  feedback: feedbackList,
  releaseDates,
  notifications: notificationList,
  validToken,
  player,
  musicRequests,
  messages: messageList,
  payments: paymentList,
  directPayment,
});

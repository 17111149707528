import React from 'react';
import { parse } from 'qs';
import { UniversalContainersLoader } from '../components/UniversalLoader';

const redirects = {
  '/artist/': '/',
  '/artist/:letter([a-zA-Z]{1})/:page?': '/',
  '/compilation/:page?': '/',
  '/live/:page?': '/',
  '/soundtrack/:page?': '/',
  '/single/:page?': '/',
  '/lastAdded/': '/last-added',
  '/lastAdded/:date': ({ match: { params } }) => `/last-added/${params.date}`,
  '/lastAdded/:date/:page': ({ match: { params } }) => `/last-added/${params.date}/${params.page}`,
  '/release/:slug-:id(\\d+)/reviews/:page?': ({ match: { params } }) => `/release/${params.slug}-${params.id}`,
  '/artist/:slug-:id(\\d+)/album/:page?': ({ match: { params } }) => `/artist/${params.slug}-${params.id}`,
  '/search.html': ({ location }) => {
    const query = parse(location.search.substr(1));

    switch (query.searchType) {
      case 'albums':
        return `/search/releases?q=${query.search}`;
      case 'songs':
        return `/search/tracks?q=${query.search}`;
      case 'artists':
        return `/search/artists?q=${query.search}`;
      default:
        return `/search${query.search ? `?q=${query.search}` : ''}`;
    }
  },
  '/sitemap': '/',
  '/promo': '/404',
  '/terms': '/shop/terms',
  '/help': '/shop/help',
  '/contact': '/shop/contact',
  '/feedbacks': '/shop/feedback',
  '/feedbacks/:page': ({ match: { params } }) => `/shop/feedback/${params.page}`,
  '/policy': '/shop/policy',
  '/signup': '/shop/signup',
  '/reset-password': '/shop/reset-password',
  '/shoppingcart/list.html': '/shop/checkout',
  '/refill.html': '/shop/top-up',
  '/profile.html': '/shop/profile',
  '/downloads.html': '/shop/downloads',
  '/paymentsHistory.html': '/404',
  '/musicRequest.html': '/shop/music-request',
  '/prefs.html': '/404',
  '/chart': '/chart/caprice',
  '/uk-top': '/chart/uk-top',
  '/us-top': '/chart/us-top',
  '/de-top': '/chart/de-top',
  '/au-top': '/chart/au-top',
  '/ca-top': '/chart/ca-top',
};

export default Object
  .keys(redirects)
  .map((path) => ({
    exact: true,
    path,
    ...(redirects[path] === '/404' ? {
      render: () => <UniversalContainersLoader page="NotFound/NotFound" />,
    } : {
      to: redirects[path],
    }),
  }));

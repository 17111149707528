import api from '../helpers/api';
import { PAYMENT_REQUESTED, PAYMENT_SUCCESS, PAYMENT_FAILED } from '../reducers/payments';

export const getPaymentByOrderId = (orderId) => {
  return (dispatch, getState) => {
    dispatch({ type: PAYMENT_REQUESTED });

    return api.get(`/payment/${orderId}`)
      .then((response) => {
        const state = getState();
        dispatch({
          type: PAYMENT_SUCCESS,
          payload: {
            [orderId]: {
              ...state.payments.data[orderId],
              ...response,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PAYMENT_FAILED,
          payload: err.body.errorMessage,
        });
      });
  };
};

export const getPaymentStatus = (orderId) => {
  return (dispatch, getState) => {
    return api.get(`/payment/${orderId}/status`)
      .then((response) => {
        const state = getState();

        dispatch({
          type: PAYMENT_SUCCESS,
          payload: {
            [orderId]: {
              ...state.payments.data[orderId],
              redirectTo: response.redirectTo,
              html: response.html,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: PAYMENT_FAILED,
          payload: err.body.errorMessage,
        });
      });
  };
};

export default {
  getPaymentByOrderId,
  getPaymentStatus,
};

import { getPaymentByOrderId } from '../../actions/payment';

const paymentsNeeds = [
  ({ orderId }) => {
    return getPaymentByOrderId(orderId);
  },
];

export default paymentsNeeds;

export {
  paymentsNeeds,
};

import { push } from 'connected-react-router';
import {
  SIDER_COLLAPSE, SIDER_RESTORE, SET_NOT_FOUND, SIDER_HIDE, SIDER_SHOW, MISC_LOADED,
} from '../reducers/app';
import api from '../helpers/api';
import {
  ACCOUNT_DISABLED, AUTH_TOKEN_EXPIRED, FORBIDDEN_ACCESS, ENTITY_NOT_FOUND,
} from '../constants';
import { USER_CLEAR, USER_FAIL } from '../reducers/user';
import analytics from '../helpers/analytics';

export const siderCollapse = () => ({ type: SIDER_COLLAPSE });
export const siderRestore = () => ({ type: SIDER_RESTORE });
export const siderHide = () => ({ type: SIDER_HIDE });
export const siderShow = () => ({ type: SIDER_SHOW });

export const setPageNotFound = () => ({ type: SET_NOT_FOUND });
export const siteDisplayed = () => ({ type: 'SITE_DISPLAYED' });
export const clearNotFoundError = () => ({ type: 'CLEAR_NOT_FOUND' });

export const handleApiErrors = (error, dispatch) => {
  const { body } = error;

  if (!body) {
    return;
  }

  switch (body.errorCode) {
    case FORBIDDEN_ACCESS:
    case AUTH_TOKEN_EXPIRED:
    case ACCOUNT_DISABLED: {
      analytics.clearUserId();

      let actions = [];

      if (body.errorCode === ACCOUNT_DISABLED) {
        actions = [
          {
            type: USER_CLEAR,
          },
          {
            type: USER_FAIL,
            payload: ACCOUNT_DISABLED,
          },
          push('/shop/login'),
        ];
      } else {
        actions = [{ type: USER_CLEAR }];
      }

      dispatch(actions);

      break;
    }
    case ENTITY_NOT_FOUND: {
      throw error;
    }

    default:
      throw error;
  }
};

export const loadMisc = () => (dispatch) => api.get('/misc').then((response) => dispatch({
  type: MISC_LOADED,
  payload: response,
}));

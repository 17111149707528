import React from 'react';
import { useSelector } from 'react-redux';
import c from './SystemMessage.less';

export default function SystemMessage() {
  const { siteMessage } = useSelector((state) => state.app.misc);
  if (!siteMessage || !siteMessage.message) return null;

  return (
    <span className={c.systemMsg}>
      {siteMessage.message}
    </span>
  );
}

import React from 'react';
import { concat } from 'ramda';

import redirects from './redirects';
import forgotPasswordNeeds from '../containers/ForgotPassword/needs';
import activateAccountNeeds from '../containers/ActivateAccount/needs';
import { homeNeeds } from '../containers/Home/needs';
import { UniversalContainersLoader } from '../components/UniversalLoader';
import { lastAddedNeeds } from '../containers/LastAdded/needs';
import { artistNeeds } from '../containers/Artist/needs';
import { releaseNeeds } from '../containers/Release/needs';
import { genreListNeeds, genreNeeds } from '../containers/Genre/needs';
import { seriesNeeds, seriesListNeeds } from '../containers/Series/needs';
import { chartNeeds } from '../containers/Chart/needs';
import { checkoutNeeds } from '../containers/Checkout/needs';
import { searchNeeds } from '../containers/Search/needs';
import feedbackNeeds from '../containers/Feedback/needs';
import { musicRequestsNeeds } from '../containers/MusicRequests/needs';
import { notificationsNeeds } from '../containers/Notifications/needs';
import { playReleaseNeeds } from '../containers/PlayRelease/needs';
import { paymentsNeeds } from '../containers/PaymentResponse/needs';
import { purchasedNeeds } from '../containers/Downloads/needs';
import directPaymentNeeds from '../containers/DirectPayment/needs';

export default concat(redirects, [{
  path: '/',
  routeComponent: <UniversalContainersLoader page="Home/Home" />,
  name: 'Home',
  needs: homeNeeds,
  exact: true,
}, {
  path: ['/last-added/:page(\\d+)?', '/last-added/:year(\\d{1,4})-:month(\\d{1,2})-:day(\\d{1,2})?/:page(\\d+)?'],
  routeComponent: <UniversalContainersLoader page="LastAdded/LastAdded" />,
  name: 'LastAdded',
  needs: lastAddedNeeds,
  exact: true,
}, {
  path: ['/artist/:slug-:id(\\d+)', '/artist/:id(\\d+)'],
  routeComponent: <UniversalContainersLoader page="Artist/Artist" />,
  name: 'Artist',
  needs: artistNeeds,
  exact: true,
}, {
  path: ['/release/:slug-:id(\\d+)', '/release/:id(\\d+)'],
  routeComponent: <UniversalContainersLoader page="Release/Release" />,
  name: 'Release',
  needs: releaseNeeds,
  exact: true,
}, {
  path: '/genre',
  exact: true,
  routeComponent: <UniversalContainersLoader page="Genre/GenreList" />,
  name: 'GenreList',
  needs: genreListNeeds,
}, {
  path: '/genre/:slug-:id(\\d+)/:page(\\d+)?',
  routeComponent: <UniversalContainersLoader page="Genre/Genre" />,
  name: 'Genre',
  needs: genreNeeds,
  exact: true,
}, {
  path: '/series',
  routeComponent: <UniversalContainersLoader page="Series/SeriesList" />,
  needs: seriesListNeeds,
  name: 'SeriesList',
  exact: true,
}, {
  path: '/series/:slug-:id(\\d+)/:page(\\d+)?',
  routeComponent: <UniversalContainersLoader page="Series/Series" />,
  needs: seriesNeeds,
  name: 'Series',
  exact: true,
}, {
  path: '/chart/:chartName',
  routeComponent: <UniversalContainersLoader page="Chart/Chart" />,
  needs: chartNeeds,
  name: 'Chart',
  exact: true,
}, {
  path: ['/shop/download/:slug-:id(\\d+)', '/shop/download/:id(\\d+)'],
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="PlayRelease/PlayRelease" />,
  needs: playReleaseNeeds,
  name: 'PlayRelease',
  exact: true,
}, {
  path: '/shop/downloads/:page(\\d+)?',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="Downloads/Downloads" />,
  name: 'Downloads',
  needs: purchasedNeeds,
  exact: true,
}, {
  path: '/shop/login',
  unauthenticatedOnly: true,
  routeComponent: <UniversalContainersLoader page="Login/Login" />,
  name: 'Login',
  exact: true,
}, {
  path: '/shop/signup',
  unauthenticatedOnly: true,
  routeComponent: <UniversalContainersLoader page="Signup/Signup" />,
  name: 'Signup',
  exact: true,
}, {
  path: '/shop/terms',
  routeComponent: <UniversalContainersLoader page="TermsOfUse/TermsOfUse" />,
  name: 'TermsOfUse',
  exact: true,
}, {
  path: '/shop/profile',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="Profile/Profile" />,
  name: 'Profile',
  exact: true,
}, {
  path: '/shop/profile/change-password',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="ChangePassword" />,
  name: 'ChangePassword',
  exact: true,
}, {
  path: '/shop/profile/change-email',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="ChangeEmail" />,
  name: 'ChangeEmail',
  exact: true,
}, {
  path: '/shop/account-statements',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="AccountStatements" />,
  name: 'AccountStatements',
  exact: true,
}, {
  path: '/search/:type?',
  routeComponent: <UniversalContainersLoader page="Search/Search" />,
  name: 'Search',
  needs: searchNeeds,
  exact: true,
}, {
  path: '/shop/reset-password',
  routeComponent: <UniversalContainersLoader page="ForgotPassword/ForgotPassword" />,
  name: 'ForgotPassword',
  needs: forgotPasswordNeeds,
  exact: true,
}, {
  path: '/shop/verify-email',
  routeComponent: <UniversalContainersLoader page="ActivateAccount/ActivateAccount" />,
  name: 'ActivateAccount',
  needs: activateAccountNeeds,
  exact: true,
}, {
  path: '/shop/checkout/:page(\\d+)?',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="Checkout/Checkout" />,
  name: 'Checkout',
  needs: checkoutNeeds,
  exact: true,
}, {
  path: '/shop/feedback/:page(\\d+)?',
  routeComponent: <UniversalContainersLoader page="Feedback/Feedback" />,
  name: 'Feedback',
  needs: feedbackNeeds,
  exact: true,
}, {
  path: '/shop/music-request/:page(\\d+)?',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="MusicRequests/MusicRequests" />,
  name: 'MusicRequests',
  needs: musicRequestsNeeds,
  exact: true,
}, {
  path: '/shop/music-request/create',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="CreateMusicRequest/CreateMusicRequest" />,
  name: 'CreateMusicRequest',
  exact: true,
}, {
  path: '/shop/contact',
  routeComponent: <UniversalContainersLoader page="ContactUs/ContactUs" />,
  name: 'ContactUs',
  exact: true,
}, {
  path: '/shop/policy',
  routeComponent: <UniversalContainersLoader page="PrivacyPolicy" />,
  name: 'PrivacyPolicy',
  exact: true,
}, {
  path: '/shop/help',
  routeComponent: <UniversalContainersLoader page="Help/Help" />,
  name: 'Help',
}, {
  path: '/shop/top-up/direct',
  routeComponent: <UniversalContainersLoader page="DirectPayment/DirectPayment" />,
  name: 'DirectPayment',
  needs: directPaymentNeeds,
  exact: true,
}, {
  path: '/shop/top-up/:page(\\d+)?',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="TopUp/TopUp" />,
  name: 'TopUp',
  exact: true,
}, {
  path: '/shop/top-up/:status/:orderId',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="PaymentResponse/PaymentResponse" />,
  name: 'Payments',
  needs: paymentsNeeds,
  exact: true,
}, {
  path: '/shop/notifications',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="Notifications/Notifications" />,
  name: 'Notifications',
  needs: notificationsNeeds,
  exact: true,
}, {
  path: '/shop/coupon/redeem',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="GiftCoupons/redeem" />,
  name: 'GiftCoupons/redeem',
  exact: true,
}, {
  path: '/shop/coupon/purchase',
  requireAuthentication: true,
  routeComponent: <UniversalContainersLoader page="GiftCoupons/purchase" />,
  name: 'GiftCoupons/purchase',
  exact: true,
}, {
  path: '*',
  routeComponent: <UniversalContainersLoader page="NotFound/NotFound" />,
  name: 'NotFound',
}]);

import { schema } from 'normalizr';

export const trackSchema = new schema.Entity('track');
export const trackListSchema = [trackSchema];

export const artistSchema = new schema.Entity('artist');
export const artistListSchema = [artistSchema];

export const genreSchema = new schema.Entity('genre');
export const genreListSchema = [genreSchema];

export const seriesSchema = new schema.Entity('series');
export const seriesListSchema = [seriesSchema];

export const releaseSchema = new schema.Entity('release');
export const releaseListSchema = [releaseSchema];

export const reviewSchema = new schema.Entity('review');
export const reviewListSchema = [reviewSchema];

export const notificationsSchema = new schema.Entity('notification');
export const notificationsListSchema = [notificationsSchema];

trackSchema.define({
  artist: artistSchema,
});

seriesSchema.define({
  children: seriesListSchema,
  parent: seriesSchema,
});

releaseSchema.define({
  tracks: trackListSchema,
  artist: artistSchema,
  series: seriesSchema,
  genres: genreListSchema,
});

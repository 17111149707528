import slug from 'speakingurl';
import { matchPath } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

import routes from '../routes';

const widths = [
  { min: 0, max: 768, value: 2 },
  { min: 768, max: 992, value: 3 },
  { min: 992, max: 1200, value: 4 },
  { min: 1200, max: Infinity, value: 4 },
];

export function getColumnsCount() {
  if (typeof window !== 'undefined') {
    const windowWidth = document.body.clientWidth;
    return widths.find((w) => windowWidth >= w.min && windowWidth < w.max).value;
  }
  return widths[widths.length - 1].value;
}

export function slugify(name, id) {
  let str = [name];
  str = str.concat(id ? [id] : []);
  return slug(str.join(' '));
}

export function getPortion(pagination) {
  const nextPagination = { ...pagination };
  const cols = getColumnsCount();

  const nextPortion = Math.floor(pagination.shownEntities / (cols * pagination.rows));

  if (nextPortion < pagination.portion) {
    nextPagination.portion = nextPortion;
  }

  if (nextPortion >= pagination.portion) {
    if (nextPortion > pagination.portion) {
      nextPagination.portion = nextPortion;
    }

    nextPagination.portion += 1;

    const newTotal = (cols * pagination.rows) * nextPagination.portion;

    const limit = newTotal - pagination.totalEntities;

    if (limit > 0) {
      return {
        pagination: nextPagination,
        query: {
          limit,
          offset: pagination.totalEntities,
        },
      };
    }
    return {
      pagination: nextPagination,
    };
  }
  nextPagination.portion += 1;
  return {
    pagination: nextPagination,
  };
}

export function isSSR() {
  return typeof window === 'undefined';
}

export function isiOS() {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

function isSafari() {
  return /^((?!chrome).)*safari/i.test(navigator.userAgent);
}

export function isSafariOnMac() {
  if (navigator.userAgent.indexOf('Mac') > 1) {
    return isSafari();
  }

  return false;
}


export function smoothScroll() {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  let wasScrolled = false;

  const onScroll = () => {
    wasScrolled = true;
  };

  const scrollToTop = () => {
    if (wasScrolled) {
      window.removeEventListener('wheel', onScroll);
      return;
    }

    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScroll > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, currentScroll - (currentScroll / 5));
    }
  };

  window.addEventListener('wheel', onScroll);

  scrollToTop();
}

export function smoothScrollTo(selector) {
  if (!selector || typeof window === 'undefined') return false;

  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  let element;
  let wasScrolled = false;

  try {
    element = document.querySelector(selector);
  } catch (e) {
    return false;
  }

  if (!element) return false;

  const contentContainer = document.querySelector('#app-container');
  const contentOffset = parseFloat(window.getComputedStyle(contentContainer, null).getPropertyValue('padding-top'));

  const onScroll = () => {
    wasScrolled = true;
  };

  const scrollToElement = () => {
    if (wasScrolled) {
      window.removeEventListener('wheel', onScroll);
      window.removeEventListener('touchmove', onScroll);
      window.removeEventListener('touchstart', onScroll);

      return;
    }

    const viewportOffset = element.getBoundingClientRect();
    const elementOffset = (viewportOffset.top + window.scrollY) - contentOffset;

    const documentScroll = (document.documentElement.scrollTop || document.body.scrollTop);

    if (documentScroll === document.body.offsetHeight - window.innerHeight && elementOffset >= documentScroll) return;

    let scrollTo;

    if (elementOffset > documentScroll) {
      scrollTo = Math.ceil(documentScroll + ((elementOffset - documentScroll) / 5));
    } else {
      scrollTo = Math.ceil(documentScroll - ((documentScroll - elementOffset) / 5));
    }

    if (documentScroll !== scrollTo) {
      window.scrollTo(0, Math.ceil(scrollTo));

      window.requestAnimationFrame(scrollToElement);
    }
  };

  window.addEventListener('wheel', onScroll);
  window.addEventListener('touchmove', onScroll);
  window.addEventListener('touchstart', onScroll);

  scrollToElement();

  return {
    cancel: () => {
      onScroll();

      window.removeEventListener('wheel', onScroll);
      window.removeEventListener('touchmove', onScroll);
      window.removeEventListener('touchstart', onScroll);
    },
  };
}

export function displayDuration(seconds) {
  const time = new Date(seconds * 1000);
  let parts = [];

  if (time.getUTCHours() > 0) {
    parts.push(time.getUTCHours());
  }

  parts = parts.concat([
    time.getUTCHours() > 0 ? `0${time.getMinutes()}`.slice(-2) : time.getMinutes(),
    `0${time.getSeconds()}`.slice(-2),
  ]);

  return parts.join(':');
}

export function activeRoute(pathname) {
  const foundRoutes = routes.reduce((acc, route) => {
    const match = matchPath(pathname, route);
    if (match) acc.push(match);
    return acc;
  }, []);

  return foundRoutes.length > 0 ? foundRoutes.shift() : null;
}

const breakpoints = {
  xs: { min: 0, max: 576 },
  sm: { min: 577, max: 767 },
  md: { min: 768, max: 991 },
  lg: { min: 992, max: 1199 },
  xl: { min: 1200, max: 1599 },
  xxl: { min: 1600, max: Infinity },
};

const responsive = {
  isMobile() {
    return responsive._is('xs');
  },

  isMobileOrTablet() {
    return responsive._is('xs') || responsive._is('sm') || responsive._is('md');
  },

  _is(br) {
    return responsive._findBreakPoint() === br;
  },

  _findBreakPoint() {
    const w = responsive._getWidth();

    return Object.keys(breakpoints).find((br) => w >= breakpoints[br].min && w <= breakpoints[br].max);
  },

  _getWidth() {
    if (typeof window !== 'undefined') {
      return document.body.clientWidth;
    }

    return Infinity;
  },
};

export const getResponsiveColumnCount = (options = {}) => {
  let columnCount = 6;

  Object.keys(options).forEach((opt) => {
    if (responsive._is(opt)) {
      columnCount = options[opt];
    }
  });

  return columnCount;
};

export const releaseTypeToAlt = (type) => {
  return typeof type === 'string' ? type.replace(/^VA_/, '').replace(/_/g, ' ') : '';
};

export const formatCurrency = (v) => numeral(v).format('$0.00');

export const toUserTZ = (d, format = 'DD.MM.YYYY HH:mm:ss') => moment.utc(d).local().format(format);

export const roundNumber = (num, decimals = 2) => {
  return Number((Math.round(num * (10 ** decimals)) / (10 ** decimals)).toFixed(decimals));
};

export { responsive };

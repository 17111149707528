import cookies from 'react-cookies';
import {
  USER_REQUESTED, USER_SUCCESS, USER_CLEAR, LOGIN_CLEAR,
  USER_FAIL,
  LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAIL,
  SIGNUP_CLEAR, SIGNUP_REQUESTED, SIGNUP_SUCCESS, SIGNUP_FAIL,
  BALANCE_SUCCESS,
} from 'reducers/user';
import { NOTIFICATION_LIST_CLEAR } from 'reducers/notifications';
import { PAYMENT_CLEAR } from 'reducers/payments';
import { MUSIC_REQUEST_LIST_CLEAR } from 'reducers/musicRequests';
import { TOKEN_INVALID } from 'reducers/reset-password';
import { isSSR } from 'helpers/view';
import analytics from 'helpers/analytics';

const AUTH_TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const cookieDomain = `.${process.env.SITE_DOMAIN}`;

export function setAuthToken(token, isLong = true) {
  cookies.save(AUTH_TOKEN_KEY, token, {
    path: '/',
    domain: cookieDomain,
    ...(isLong ? {
      maxAge: 60 * 60 * 24 * 90,
    } : {}),
  });
}

export function getAuthToken() {
  return cookies.load(AUTH_TOKEN_KEY, {
    path: '/',
    domain: cookieDomain,
  });
}

export function removeAuthToken() {
  cookies.remove(AUTH_TOKEN_KEY, {
    path: '/',
    domain: cookieDomain,
  });
}

export function setRefreshToken(token, remember) {
  if (remember) {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  } else {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
}

export function getRefreshToken() {
  if (sessionStorage.getItem(REFRESH_TOKEN_KEY)) {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
  }

  if (localStorage.getItem(REFRESH_TOKEN_KEY)) {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  return false;
}

export function removeRefreshToken() {
  if (!isSSR()) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  }
}

export function getUser() {
  return (dispatch, getState, api) => { // eslint-disable-line
    dispatch({ type: USER_REQUESTED });

    return api.get('/account').then((response) => {
      dispatch({
        type: USER_SUCCESS,
        payload: {
          profile: response,
        },
      });
    }, (err) => {
      if (err.body) {
        dispatch({
          type: USER_FAIL,
          payload: err.body.errorCode,
        });
      }

      return new Error(err);
    });
  };
}

export function login({
  username, password, remember, validation,
}) {
  return (dispatch, _, api) => {
    dispatch({ type: LOGIN_REQUESTED });

    return api.post('/auth/signin', {
      username,
      password,
    }, validation ? {
      'g-recaptcha-response': validation,
    } : {}).then((response) => {
      removeRefreshToken();

      setAuthToken(response.authToken, remember);
      setRefreshToken(response.refreshToken, remember);

      dispatch([{
        type: LOGIN_SUCCESS,
      }, {
        type: USER_SUCCESS,
        payload: {
          profile: {
            username: response.username,
          },
        },
      }]);
    }).catch(({ body }) => {
      let message = 'Oops, something went wrong.';

      if (body) {
        if (body.errorCode === 'BAD_CREDENTIALS') {
          message = 'Username or password is incorrect';
        } else if (body.errorMessage) {
          message = body.errorMessage;
        }
      }

      dispatch({
        type: LOGIN_FAIL,
        payload: {
          message,
          errorCode: body ? body.errorCode : null,
        },
      });
      setImmediate(() => {
        dispatch({ type: LOGIN_CLEAR });
      });
    });
  };
}

export function logout() {
  removeAuthToken();
  removeRefreshToken();

  analytics.clearUserId();

  return ([{
    type: USER_CLEAR,
  }, {
    type: NOTIFICATION_LIST_CLEAR,
  }, {
    type: MUSIC_REQUEST_LIST_CLEAR,
  }, {
    type: PAYMENT_CLEAR,
  }, {
    type: TOKEN_INVALID,
  }]);
}

export function signUp(data, headers) {
  return (dispatch, _, api) => {
    dispatch([{
      type: USER_CLEAR,
    }, {
      type: SIGNUP_REQUESTED,
    }]);

    return api.post('/account', data, headers)
      .then((response) => {
        setAuthToken(response.authToken);
        setRefreshToken(response.refreshToken, false);

        dispatch([{
          type: SIGNUP_SUCCESS,
        }, {
          type: LOGIN_SUCCESS,
        }, {
          type: USER_SUCCESS,
          payload: {
            profile: {
              username: response.username,
              userEmail: data.userEmail,
            },
          },
        }]);
      })
      .catch((e) => {
        dispatch({
          type: SIGNUP_FAIL,
          payload: e,
        });

        return Promise.reject(e);
      });
  };
}

export function clearSignUp() {
  return (dispatch) => dispatch({ type: SIGNUP_CLEAR });
}

export function getProfile() {
  return (dispatch, _, api) => {
    dispatch({ type: USER_REQUESTED });

    return api.get('/account/profile')
      .then((response) => {
        dispatch({
          type: USER_SUCCESS,
          payload: {
            profile: response,
          },
        });
      });
  };
}

export function updateProfile(fiedls) {
  return (dispatch, _, api) => {
    dispatch({ type: USER_REQUESTED });

    return api.put('/account/profile', fiedls)
      .then((response) => {
        dispatch({
          type: USER_SUCCESS,
          payload: {
            profile: response,
          },
        });
      });
  };
}

export function updatePassword(fields) {
  return (__, _, api) => {
    return api.put('/account/password', fields)
      .then((response) => {
        setAuthToken(response.authToken, false);
        removeRefreshToken();
      });
  };
}

export function getEmailPrefs() {
  return (dispatch, _, api) => {
    dispatch({ type: USER_REQUESTED });

    return api.get('/account/email-prefs')
      .then((response) => {
        dispatch({
          type: USER_SUCCESS,
          payload: {
            emailPrefs: response,
          },
        });
      });
  };
}

export function updateEmailPrefs(fields) {
  return (dispatch, _, api) => {
    dispatch({ type: USER_REQUESTED });
    return api.put('/account/email-prefs', fields)
      .then((response) => {
        dispatch({
          type: USER_SUCCESS,
          payload: {
            emailPrefs: response,
          },
        });
      });
  };
}

export const getBalance = () => (dispatch, _, api) => {
  return api.get('/balance')
    .then((response) => {
      dispatch({
        type: BALANCE_SUCCESS,
        payload: {
          balance: response.balance,
        },
      });
    });
};

import { defaultTo, subtract } from 'ramda';

import { seriesById, getSeriesList } from '../../actions/series';
import { releasesBySeries } from '../../actions/release';
import { defaultPagination } from '../../components/Pagination/common';

const seriesNeeds = [
  (params) => seriesById(params.id),
  (params, store) => {
    const currentCategory = store.series.data[params.id];

    if (currentCategory && currentCategory.parentId) {
      return seriesById(currentCategory.parentId);
    }

    return { type: '' };
  },
  (params, store) => {
    if (store.series.data[params.id]) {
      return releasesBySeries(params.id, {
        limit: defaultPagination.rows * defaultPagination.maxCols,
        offset: defaultPagination.rows * defaultPagination.maxCols * subtract(parseInt(defaultTo(1, params.page), 10), 1),
      });
    }
    return { type: '' };
  },
];

const seriesListNeeds = [
  () => getSeriesList(),
];

export {
  seriesNeeds,
  seriesListNeeds,
};

import { getHomeLastAdded, getHomeRecommended, getPromo } from 'actions/release';
import { MAX_COLS, ROWS } from './common';

const homeNeeds = [
  () => getHomeLastAdded({ limit: MAX_COLS * ROWS }),
  () => getHomeRecommended(),
  () => getPromo(),
];

export default homeNeeds;

export {
  homeNeeds,
};

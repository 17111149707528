export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

const initialState = {};

export function messageList(store = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...store,
        [action.payload.key]: action.payload,
      };
    case CLEAR_MESSAGE: {
      const nextStore = { ...store };
      delete nextStore[action.payload.key];

      return nextStore;
    }
    default:
      return store;
  }
}

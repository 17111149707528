import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchIcon from 'material-design-icons/action/svg/production/ic_search_24px.svg';
import MenuIcon from 'material-design-icons/navigation/svg/production/ic_menu_36px.svg';

import { isSSR } from '../../helpers/view';
import { siderCollapse, siderRestore, loadMisc } from '../../actions/app';
import { loadCartSummary } from '../../actions/shoppingCart';
import { getBalance, getUser } from '../../actions/user';
import CartIndicator from '../CartIndicator';
import Search from '../Search';
import UserMenu from '../UserMenu';
import Logo from '../Logo';
import UserNotifications from '../UserNotifications';
import Balance from '../Balance';
import MyMusicIcon from './assets/my-music.svg';
import PersonIcon from './assets/person-material.svg';

import c from './Header.less';

const HIDE_ON_WIDTH = 992;

function MyMusicLink({ className }) {
  return (
    <Link to="/shop/downloads" className={className} aria-label="Downloads">
      <MyMusicIcon />
    </Link>
  );
}

MyMusicLink.propTypes = {
  className: PropTypes.string,
};

MyMusicLink.defaultProps = {
  className: '',
};

function UserLoggedInBlock({ balance, username }) {
  return (
    <Fragment>
      <CartIndicator className={classNames(c.cartIndicator, c.headerIcon)} />
      <MyMusicLink className={classNames('visible-desktop', c.musicLink, c.headerIcon)} />
      <UserMenu>
        <button type="button" className={c.userMenuToggle}>
          <UserNotifications className={c.personIcon} />
          <div className={classNames('visible-desktop', c.userInfo)}>
            <strong className={c.username}>{username}</strong>
            <span className={c.balance}>
              <Balance value={balance} />
            </span>
          </div>
        </button>
      </UserMenu>
    </Fragment>
  );
}

UserLoggedInBlock.propTypes = {
  username: PropTypes.string,
  balance: PropTypes.number,
};

UserLoggedInBlock.defaultProps = {
  username: '',
  balance: 0,
};

function UserLoggedOutBlock({ loginLink }) {
  return (
    <Fragment>
      <div className={classNames('visible-desktop')}>
        <Link to={loginLink} className={c.loginBtn}>Login</Link>
        <Link className={c.signUpBtn} to="/shop/signup">Sign Up</Link>
      </div>
      <div className={classNames('hidden-desktop', c.anonymousLogin, c.headerIcon)}>
        <Link to={loginLink} className={c.personIcon}>
          <PersonIcon />
        </Link>
      </div>
    </Fragment>
  );
}

UserLoggedOutBlock.propTypes = {
  loginLink: PropTypes.string,
};

UserLoggedOutBlock.defaultProps = {
  loginLink: '',
};

class HeaderComponent extends Component {
  static propTypes = {
    siderCollapse: PropTypes.func.isRequired,
    siderRestore: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    getBalance: PropTypes.func.isRequired,
    loadCartSummary: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    loadMisc: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.handleResize = this._handleResize.bind(this);
  }

  state = {
    showSearchInput: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.siderChange(this.canBeCollapsed());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname
      && !/^\/search\/?(.*)/.test(nextProps.location.pathname)
      && this.state.showSearchInput
    ) {
      this.handleToggleSearchInput();
    }

    if (nextProps.location.pathname !== this.props.location.pathname && nextProps.user.loggedIn) {
      this.props.getBalance();
      this.props.loadCartSummary();
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.loadMisc();
    }

    if (nextProps.user.loggedIn && this.props.user.loggedIn !== nextProps.user.loggedIn) {
      this.props.getUser();
    }
  }

  componentWillUnmount() {
    if (!isSSR()) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  showSider = (e) => {
    e.preventDefault();
    document.body.classList.add('disable-scroll');
    this.siderChange(false);
  };

  canBeCollapsed = () => document.body.clientWidth <= HIDE_ON_WIDTH;

  handleToggleSearchInput = () => {
    const body = document.querySelector('body');

    if (this.state.showSearchInput) {
      body.classList.remove('header-expanded');
    } else {
      body.classList.add('header-expanded');
      setImmediate(() => this.searchInput && this.searchInput.focus());
    }

    this.setState((state) => ({
      showSearchInput: !state.showSearchInput,
    }));
  };

  _handleResize() {
    if (this.props.app.siderCollapsed && !this.canBeCollapsed()) {
      this.props.siderRestore();
    }

    if (!this.props.app.siderCollapsed && this.canBeCollapsed()) {
      this.props.siderCollapse();
    }
  }

  siderChange(collapsed) {
    if (collapsed) {
      this.props.siderCollapse();
    } else {
      this.props.siderRestore();
    }
  }

  render() {
    const { user } = this.props;
    const { showSearchInput } = this.state;

    return (
      <header className={classNames(c.headerContainer, { [c.containerSearchInputVisible]: showSearchInput })}>
        <div className={c.innerHeader}>
          <button type="button" onClick={this.showSider} className={classNames('hidden-desktop', c.menuFold)}>
            <MenuIcon className={classNames(c.triggerIcon, c.icons)} />
          </button>

          <div className={classNames(c.logoPlaceholder)}>
            <Link to="/" className={classNames(c.logoLink)}>
              <Logo className={c.logo} width={106} height={46} />
            </Link>
          </div>

          <div className={classNames(c.searchInput, 'visible-desktop', { [c.searchInputVisible]: showSearchInput })}>
            <Search
              refInput={(el) => {
                this.searchInput = el;
              }}
            />
          </div>

          <div className={c.rightIconsWrapper}>
            <div
              className={classNames('hidden-desktop', c.headerIcon, c.searchIcon, { [c.searchIconActive]: showSearchInput })}
            >
              <button type="button" onClick={this.handleToggleSearchInput}>
                <SearchIcon className={c.icons} />
              </button>
            </div>
            {
              user.loggedIn
                ? <UserLoggedInBlock balance={user.data.balance} username={user.data.profile.username} />
                : <UserLoggedOutBlock loginLink="/shop/login" />
            }
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  app: store.app,
});

const mapDispatchToProps = {
  getUser,
  siderCollapse,
  siderRestore,
  getBalance,
  loadCartSummary,
  loadMisc,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));

import { parse } from 'qs';
import { getDirectPayment } from '../../actions/directPayment';

export default [
  (params, state, match, search) => {
    const { token } = parse(search);

    return getDirectPayment(token);
  },
];

import { parse } from 'qs';
import { validateToken } from '../../actions/account';

export default [
  (params, state, match, search) => {
    const { token } = parse(search);

    return validateToken(token);
  },
];

import React from 'react';
import PropTypes from 'prop-types';

import Menu from '../Menu';
import pages from '../../containers/Help/pages';

import c from './HelpMenu.less';

export default function HelpMenu(props) {
  const menuItems = pages
    .filter((page) => page.title)
    .map((page) => ({
      key: page.path,
      url: `/shop/help${page.path}`,
      title: page.title,
      component: page.routeComponent,
      exact: true,
    }));

  return (
    <Menu
      title="Help"
      items={menuItems}
      onItemClick={props.onItemClick}
      onItemHover={props.onItemHover}
      containerClassName={c.container}
      itemClassName={c.item}
      listContainerClassName={c.list}
    />
  );
}

HelpMenu.propTypes = {
  onItemClick: PropTypes.func,
  onItemHover: PropTypes.func,
};

HelpMenu.defaultProps = {
  onItemClick: () => null,
  onItemHover: () => null,
};

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';

import SheetTitle from '../../components/SheetTitle/SheetTitle';
import pages from './pages';

import c from './TopicList.less';

class TopicList extends React.PureComponent {
  render() {
    const menuItems = pages
      .filter((page) => page.title)
      .map((page) => ({
        key: page.path,
        url: `/shop/help${page.path}`,
        title: page.title,
        exact: true,
      }));

    return (
      <div className={c.container}>
        <SheetTitle title="Help" />
        <Row gutter={{ md: 20 }}>
          {
          menuItems.map((item) => (
            <Col sm={24} md={12} key={item.key}>
              <div className={c.menuItem}>
                <NavLink exact={item.exact} to={item.url} activeClassName={c.selected}>
                  {item.title}
                </NavLink>
              </div>
            </Col>
          ))
        }
        </Row>
      </div>
    );
  }
}

export default withRouter(TopicList);

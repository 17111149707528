import { reviewByReleaseId } from '../../actions/review';
import { releaseById } from '../../actions/release';

const playReleaseNeeds = [
  (params) => releaseById(params.id),
  (params) => reviewByReleaseId({ releaseId: params.id }),
];

export {
  playReleaseNeeds,
};

export default playReleaseNeeds;

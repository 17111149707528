/**
 * Main store function
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk-recursion-detect';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import { reduxBatch } from '@manaflair/redux-batch';
import { routerMiddleware } from 'connected-react-router';
import { initApi } from './helpers/api';

import { handleApiErrors } from './actions/app';
import createRootReducer from './reducers';
import message from './actions/message';
import initHistory from './history';

const myErrorHandler = (dispatch, err) => { // eslint-disable-line
  if (err.body && err.body.errorCode) {
    let config = {};

    switch (err.body.errorCode) {
      case 'AUTH_TOKEN_EXPIRED':
        config = {
          content: 'Session has expired. Please login.',
          key: 'session_expired',
          duration: 3,
        };
        break;
      case 'ENTITY_NOT_FOUND':
      case 'SHOPPING_CART_IS_FULL': {
        return Promise.reject(err);
      }
      default:
        config = {
          content: 'Something went wrong. Please try to refresh the page.',
          key: 'api_error',
          duration: 10,
        };
    }

    message.error(config)(dispatch);

    return handleApiErrors.bind(this, err);
  }

  return Promise.reject(err);
};

export const appHistory = initHistory();

function configureStore(initialState = {}, history) {
  const composeEnhancers = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const errorHandlerMiddleware = ({ dispatch }) => createThunkErrorHandlerMiddleware({ onError: myErrorHandler.bind(this, dispatch) })({ dispatch });
  const thunkMiddlewareWithApi = ({ dispatch, getState }) => thunkMiddleware.withExtraArgument(initApi(dispatch))({ dispatch, getState });
  const createdStore = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      reduxBatch,
      applyMiddleware(
        routerMiddleware(history),
        errorHandlerMiddleware,
        thunkMiddlewareWithApi,
      ),
      reduxBatch,
    )
  );

  // For hot reloading reducers
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // const nextReducer = require('./reducers/index').default; // eslint-disable-line global-require
      createdStore.replaceReducer(createRootReducer(history));
    });
  }

  return createdStore;
}

function store(history) {
  let storeContainer = null;

  return {
    init(initialState = {}, h) {
      return configureStore(initialState, h);
    },

    withHistory(initialState = {}) {
      if (!storeContainer) {
        storeContainer = this.init(initialState, history);
      }

      return storeContainer;
    },

    getContainer() {
      return storeContainer;
    },
  };
}

export default store(appHistory);

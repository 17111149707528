import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Affix } from 'antd';
import { connect } from 'react-redux';
import SuccessIcon from 'material-design-icons/action/svg/production/ic_check_circle_24px.svg';
import CancelIcon from 'material-design-icons/navigation/svg/production/ic_cancel_24px.svg';
import WarningIcon from 'material-design-icons/alert/svg/production/ic_warning_24px.svg';

import { removeMessage } from '../../actions/message';
import { responsive } from '../../helpers/view';

import c from './Message.less';

class Message extends React.PureComponent {
  static propTypes = {
    messages: PropTypes.objectOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      type: PropTypes.string,
      duration: PropTypes.number,
    })).isRequired,
    removeMessage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { messages } = this.props;

    if (Object.keys(messages).length > 0) {
      Object.keys(messages).forEach((k) => {
        setTimeout(() => {
          this.props.removeMessage(messages[k].key);
        }, messages[k].duration);
      });
    }
  }

  getIcon = (type) => {
    return ({
      success: SuccessIcon,
      error: CancelIcon,
      warning: WarningIcon,
    })[type];
  };

  createHandleMessageClick = (messageKey) => () => {
    this.props.removeMessage(messageKey);
  };

  renderMessage = (message) => {
    const MessageIcon = this.getIcon(message.type);
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        tabIndex={0}
        role="button"
        key={message.key}
        className={classNames(c.content, c[`status-${message.type}`])}
        onClick={this.createHandleMessageClick(message.key)}
      >
        <MessageIcon className={c.icon} />
        <span>{message.content}</span>
      </div>
    );
  };

  render() {
    const { messages } = this.props;

    if (Object.keys(messages).length === 0) return null;

    return (
      <Affix offsetTop={responsive.isMobileOrTablet() ? 64 : 0} className={c.affixContainer}>
        {
          Object.keys(messages).map((k) => this.renderMessage(messages[k]))
        }
      </Affix>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});

const mapDispatchToProps = ({
  removeMessage,
});

export const ConnectedMessage = connect(mapStateToProps, mapDispatchToProps)(Message);

export default {
  Message: ConnectedMessage,
};

import { normalize } from 'normalizr';
import { slugify } from '../helpers/view';
import { SERIES_SUCCESS, SERIES_REQUESTED, SERIES_ERROR } from '../reducers/series';
import api from '../helpers/api';
import { seriesSchema, seriesListSchema } from '../schemas';

export function getSeriesList() {
  return (dispatch) => {
    dispatch({ type: SERIES_REQUESTED });

    return api.get('/series')
      .then((response) => {
        const responseMapped = response.map((seriesEntry) => ({
          slug: slugify(seriesEntry.name, seriesEntry.id),
          ...seriesEntry,
        }));
        const data = normalize(responseMapped, seriesListSchema);

        dispatch({
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        });
      });
  };
}

export function seriesById(id) {
  return (dispatch) => {
    dispatch({ type: SERIES_REQUESTED });
    return api.get(`/series/${id}`)
      .then((response) => {
        const data = normalize(response, seriesSchema);

        dispatch({
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        });
      })
      .catch(() => {
        dispatch({
          type: SERIES_ERROR,
        });
      });
  };
}

import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import SiteLogo from './assets/logo.png';
import c from './FacebookShare.less';

export default function FacebookShare() {
  return (
    <Fragment>
      <Helmet defer={false}>
        <script>
          {`
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=1488350481418079";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        `}
        </script>
        <meta property="og:title" content="Mp3Caprice - High Quality Music Downloads" />
        <meta
          property="og:description"
          content="Mp3Caprice offers a big variety of mp3 music in different styles and genres. Discover, buy and download high quality tunes for reasonable prices!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://mp3caprice.com${SiteLogo}`} />
        <meta property="og:url" content="http://mp3caprice.com" />
        <meta property="og:site_name" content="Mp3Caprice.com" />
        <meta property="fb:admins" content="100001529135271" />
        <meta property="fb:app_id" content="1488350481418079" />
      </Helmet>
      <div
        data-href="http://mp3caprice.com"
        className={cx('fb-like', c.fbLike)}
        data-layout="button_count"
        data-action="like"
        data-size="small"
        data-show-faces="true"
        data-share="true"
      />
    </Fragment>
  );
}

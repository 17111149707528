export const PAYMENT_REQUESTED = 'PAYMENT_REQUESTED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export function paymentList(store = initialState, action) {
  switch (action.type) {
    case PAYMENT_REQUESTED:
      return {
        ...store,
        loading: true,
        error: null,
      };
    case PAYMENT_SUCCESS:
      return {
        loading: false,
        error: null,
        data: {
          ...store.data,
          ...action.payload,
        },
      };
    case PAYMENT_FAILED: {
      return {
        ...store,
        loading: false,
        error: action.payload,
      };
    }
    case PAYMENT_CLEAR: {
      return initialState;
    }
    default:
      return store;
  }
}

import React from 'react';
import { Redirect } from 'react-router-dom';

import { UniversalContainersLoader } from '../../components/UniversalLoader';
import { responsive, isSSR } from '../../helpers/view';
import TopicList from './TopicList';

export default [
  {
    path: '',
    render: () => {
      if (!isSSR() && !responsive.isMobileOrTablet()) {
        return <Redirect to="/shop/help/how-to-register" />;
      }

      return (<TopicList />);
    },
  },
  {
    path: '/how-to-register',
    routeComponent: <UniversalContainersLoader page="Help/pages/HowToRegister" />,
    title: 'How to register/sign up',
  },
  {
    path: '/cannot-login',
    routeComponent: <UniversalContainersLoader page="Help/pages/CannotLogin" fallback={null} />,
    title: 'I cannot login/forgotten password',
  },
  {
    path: '/how-to-find-music',
    routeComponent: <UniversalContainersLoader page="Help/pages/FindMusic" />,
    title: 'How do I find music?',
  },
  {
    path: '/music-request',
    routeComponent: <UniversalContainersLoader page="Help/pages/MusicRequest" />,
    title: 'I have a music request',
  },
  {
    path: '/fill-balance',
    to: '/buy-music',
  },
  {
    path: '/buy-music',
    routeComponent: <UniversalContainersLoader page="Help/pages/BuyMusic" />,
    title: 'How do I buy music',
  },
  {
    path: '/download-music',
    routeComponent: <UniversalContainersLoader page="Help/pages/DownloadMusic" />,
    title: 'How to download music',
  },
  {
    path: '/file-archives',
    routeComponent: <UniversalContainersLoader page="Help/pages/FileArchives" />,
    title: 'File archives',
  },
  {
    path: '/request-refund',
    routeComponent: <UniversalContainersLoader page="Help/pages/RequestRefund" />,
    title: 'Request a refund',
  },
  {
    path: '/archive-doesnt-unzip',
    routeComponent: <UniversalContainersLoader page="Help/pages/ArchiveDoesntUnzip" />,
    title: 'The archive doesn\'t unzip',
  },
  {
    path: '/downloaded-track-issue',
    routeComponent: <UniversalContainersLoader page="Help/pages/DownloadedTrackIssue" />,
    title: 'I have a problem with a downloaded track/album',
  },
  {
    path: '/cannot-topup-balance',
    routeComponent: <UniversalContainersLoader page="Help/pages/CannotTopUp" />,
    title: 'I cannot top up my balance',
  },
  {
    path: '/affiliate-program',
    routeComponent: <UniversalContainersLoader page="Help/pages/AffiliateProgram" />,
    title: 'Affiliate program',
  },
  {
    path: '/advertisement',
    routeComponent: <UniversalContainersLoader page="Help/pages/Advertisement" />,
    title: 'Advertisement',
  },
  {
    path: '*',
    routeComponent: <UniversalContainersLoader page="NotFound/NotFound" />,
  },
];

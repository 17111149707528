export const ARTIST_SUCCESS = 'ARTIST_SUCCESS';
export const ARTIST_REQUESTED = 'ARTIST_REQUESTED';
export const ARTIST_ERROR = 'ARTIST_ERROR';

const initialStore = {
  loading: false,
  error: false,
  data: {},
};

export function artistList(store = initialStore, action) {
  switch (action.type) {
    case ARTIST_REQUESTED:
      return {
        ...store,
        loading: true,
        error: false,
      };
    case ARTIST_SUCCESS:
      return {
        loading: false,
        error: false,
        data: {
          ...store.data,
          ...action.payload,
        },
      };
    case ARTIST_ERROR:
      return {
        ...store,
        loading: false,
        error: true,
      };
    default:
      return store;
  }
}

import { createBrowserHistory } from 'history';
import qhistory from 'qhistory';
import { stringify, parse } from 'qs';

let history;

export default function initHistory() {
  if (!history) {
    history = typeof window !== 'undefined' ? qhistory(
      createBrowserHistory(),
      stringify,
      parse
    ) : null;
  }

  return history;
}

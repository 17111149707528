import { listCart } from '../../actions/shoppingCart';
import { itemsPerPage } from './common';

const checkoutNeeds = [
  () => listCart({
    limit: itemsPerPage,
  }),
];

export default {
  checkoutNeeds,
};

export {
  checkoutNeeds,
};

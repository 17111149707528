import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { stringify, parse } from 'qs';

import { clearAutocomplete } from '../../actions/search';
import { activeRoute, smoothScroll } from '../../helpers/view';
import c from './Search.less';

class Search extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    clearAutocomplete: PropTypes.func.isRequired,
    refInput: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    refInput: () => {
    },
  };

  handleChange = ({ target: { value } }) => {
    const { params: { type } } = activeRoute(this.props.history.location.pathname);
    const searchQuery = {
      q: value.length > 0 ? value : null,
    };

    this.props.history.push(`/search${type ? `/${type}` : ''}?${stringify(searchQuery)}`);

    this.forceUpdate();
  };

  handleClearInput = () => {
    this.props.history.push('/search');

    this.forceUpdate();

    this.props.clearAutocomplete();
  };

  handleInputFocus = () => {
    const { query: { q }, pathname } = this.props.location;
    let nextPath = '/search';

    if (pathname.startsWith('/search')) {
      nextPath = pathname;
    }

    this.props.history.push(`${nextPath}${q && q.length > 0 ? `?${stringify({ q })}` : ''}`);

    smoothScroll();
  };

  render() {
    const { history: { location } } = this.props;
    const { q } = parse(location.search.slice(1));

    return (
      <div
        className={classNames(
          c.certainCategorySearchWrapper,
          this.props.className,
          {
            [c.inAction]: q,
          }
        )}
      >
        <Input
          onChange={this.handleChange}
          onFocus={this.handleInputFocus}
          value={q}
          placeholder="Search music"
          className={c.input}
          ref={(el) => this.props.refInput(el)}
          suffix={(
            <Icon
              type={q ? 'close' : 'search'}
              className={classNames(c.certainCategoryIcon, { [c.activeIcon]: q })}
              onClick={q ? this.handleClearInput : null}
            />
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  clearAutocomplete,
};

export default withRouter(connect(null, mapDispatchToProps)(Search));

/**
 * Client entry point
 */
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import App from './App';

const renderApp = (Component) => {
  hydrate(
    <Component />,
    document.getElementById('root')
  );
};

loadableReady(() => {
  renderApp(App);
});

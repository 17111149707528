import { normalize } from 'normalizr';
import { GENRES_SUCCESS, GENRES_REQUESTED, GENRES_ERROR } from '../reducers/genre';
import api from '../helpers/api';
import { slugify } from '../helpers/view';
import { genreSchema, genreListSchema } from '../schemas';

export function getGenreList() {
  return (dispatch) => {
    dispatch({ type: GENRES_REQUESTED });
    return api.get('/genres')
      .then((response) => {
        const responseMapped = response.map((genreEntry) => ({
          slug: slugify(genreEntry.name, genreEntry.id),
          ...genreEntry,
        }));
        const data = normalize(responseMapped, genreListSchema);
        dispatch({
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        });
      });
  };
}

export function getGenreById(id) {
  return (dispatch) => {
    dispatch({ type: GENRES_REQUESTED });
    return api.get(`/genres/${id}`)
      .then((response) => {
        const data = normalize(response, genreSchema);
        dispatch({
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        });
      })
      .catch(() => {
        dispatch({
          type: GENRES_ERROR,
        });
      });
  };
}

import { normalize } from 'normalizr';
import api from '../helpers/api';
import { reviewListSchema } from '../schemas';
import {
  REVIEWS_REQUESTED, REVIEWS_SUCCESS,
  RELEASE_REVIEWS_SUCCESS,
} from '../reducers/review';

export function reviewByReleaseId({
  offset = 0, limit = 5, releaseId, append = false,
} = {}) {
  return (dispatch, getState) => {
    dispatch({ type: REVIEWS_REQUESTED });

    return api.get(`/reviews?release=${releaseId}&offset=${offset}&limit=${limit}`)
      .then((response) => {
        const data = normalize(response.items, reviewListSchema);

        let reviewList = data.result;

        if (append) {
          const state = getState();
          reviewList = state.reviews.byRelease.ids[releaseId].concat(data.result);
        }

        dispatch([{
          type: REVIEWS_SUCCESS,
          payload: data.entities.review,
        }, {
          type: RELEASE_REVIEWS_SUCCESS,
          payload: {
            total: response.total,
            releaseId,
            reviewList,
          },
        }]);
      });
  };
}

export default {
  reviewByReleaseId,
};

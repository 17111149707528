import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import {
  Form, Input, Select, Button, Row, Col, Spin,
} from 'antd';
import { connect } from 'react-redux';
import SendIcon from 'material-design-icons/content/svg/production/ic_send_48px.svg';

import { getProfile } from 'actions/user';

import api from 'helpers/api';
import c from './ContactUsForm.less';

const layout = {
  left: {
    xs: 24,
    md: 6,
  },
  right: {
    xs: 24,
    md: 18,
  },
  offset: {
    xs: {
      offset: 0,
    },
    md: {
      offset: 6,
    },
  },
};

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const SUBJECTS = {
  CUSTOM_SUBJECT_ID: 0,
  DOWNLOADED_TRACK_SUBJECT_ID: 3,
  MUSIC_REQUEST: 6,
};

class ContactUsForm extends React.Component {
  recaptchaRef = null;

  static propTypes = {
    onClickOk: PropTypes.func,
    onSuccess: PropTypes.func,
    form: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    defaultSubject: PropTypes.number,
  };

  static defaultProps = {
    onClickOk: () => null,
    onSuccess: () => null,
    defaultSubject: null,
  };

  state = {
    subjectList: [],
    loadingSubjectList: false,
    submittingForm: false,
    showForm: true,
    showSuccessMessage: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      loadingSubjectList: true,
    });

    api.get('/contact-us/subjects').then((response) => {
      this.setState({
        subjectList: response,
      });
    }).then(() => {
      this.setState({
        loadingSubjectList: false,
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, user } = this.props;

    form.validateFields(async (err, formValues) => {
      if (err) return;

      this.setState({
        submittingForm: true,
      });

      const postData = {
        ...(Object.keys(formValues).reduce((acc, key) => {
          acc[key] = typeof formValues[key] !== 'undefined' ? formValues[key] : '';
          return acc;
        }, {})),
        ...(formValues.releaseLink ? {
          message: `${formValues.message}\r\nLink to release: ${formValues.releaseLink}`,
        } : {}),
        validation: undefined,
        releaseLink: undefined,
        ...(formValues.subjectId !== SUBJECTS.CUSTOM_SUBJECT_ID ? {
          subject: this.state.subjectList.find((subject) => subject.id === formValues.subjectId).subject,
        } : {}),
      };

      if (user.loggedIn) {
        if (!user.data.profile.userEmail) {
          await this.props.getProfile();
        }

        Object.assign(postData, {
          userName: user.data.profile.username,
          email: user.data.profile.userEmail,
        });
      }

      const headers = { 'g-recaptcha-response': formValues.validation };

      api.post('/contact-us', postData, headers)
        .then(() => {
          this.setState({
            showForm: false,
            showSuccessMessage: true,
          });
          this.props.onSuccess();
        }, ({ body }) => {
          if (body.errorCode === 'INVALID_RECAPTCHA_RESPONSE') {
            this.recaptchaRef.reset();
          }
        })
        .then(() => {
          this.setState({
            submittingForm: false,
          });
        });
    });
  };

  handleReCaptcha = (response) => {
    this.props.form.setFieldsValue({
      validation: response,
    });
  };

  handleOkClick = () => {
    this.props.onClickOk();
  };

  render() {
    const { form: { getFieldDecorator, getFieldValue, getFieldError }, user } = this.props;
    const {
      subjectList, showForm, showSuccessMessage, submittingForm, loadingSubjectList,
    } = this.state;

    return (
      <Fragment>
        {
          showSuccessMessage && (
            <div className={c.successMessage}>
              <div className={c.messageIcon}><SendIcon /></div>
              <p>Your Message has been sent successfully.</p>
              <Button className={c.okButton} onClick={this.handleOkClick}>Ok</Button>
            </div>
          )
        }
        {
          showForm && (
            <Spin spinning={loadingSubjectList}>
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col {...layout.left} className={c.label}>Subject </Col>
                  <Col {...layout.right}>
                    <FormItem className={c.input}>
                      {getFieldDecorator('subjectId', {
                        initialValue: subjectList.length > 0 ? this.props.defaultSubject : null,
                        rules: [{ required: true, message: 'Please select a subject.' }],
                      })(
                        <Select placeholder="Please select a subject">
                          {
                            subjectList.map((subject) => (
                              <Option key={subject.id} value={subject.id}>{subject.subject}</Option>))
                          }
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                {
                  getFieldValue('subjectId') === SUBJECTS.DOWNLOADED_TRACK_SUBJECT_ID && (
                    <Row>
                      <Col {...layout.left} className={c.label}>Link to release page </Col>
                      <Col {...layout.right}>
                        <FormItem
                          hasFeedback
                          className={c.input}
                          help={!getFieldError('releaseLink')
                            ? 'On the page where the album is located in the address bar, simply copy the address in your browser and paste here'
                            : undefined}
                        >
                          {getFieldDecorator('releaseLink', {
                            rules: [{
                              required: true,
                              message: 'Please enter a link to release.',
                            }, {
                              type: 'url',
                              message: 'Please enter a valid link to release.',
                            }],
                          })(
                            <Input type="url" />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )
                }
                {
                  getFieldValue('subjectId') === SUBJECTS.CUSTOM_SUBJECT_ID && (
                    <Row>
                      <Col {...layout.offset}>
                        <FormItem
                          hasFeedback
                          className={c.input}
                        >
                          {getFieldDecorator('subject', {
                            rules: [{ required: true, message: 'Please enter email subject' }],
                          })(
                            <Input />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )
                }
                {
                  getFieldValue('subjectId') === SUBJECTS.MUSIC_REQUEST && (
                    <Row>
                      <Col {...layout.offset}>
                        <p className={c.musicRequestNote}>
                          Please use <Link to="/shop/music-request/create" onClick={this.handleOkClick}>this form to place</Link>{' '}
                          your music requests
                        </p>
                      </Col>
                    </Row>
                  )
                }
                {
                  !user.loggedIn && (
                    <Fragment>
                      <Row>
                        <Col {...layout.left} className={c.label}>Your name </Col>
                        <Col {...layout.right}>
                          <FormItem
                            hasFeedback
                            className={c.input}
                          >
                            {getFieldDecorator('userName', {
                              rules: [{ required: true, message: 'Please enter your name' }],
                            })(
                              <Input />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col {...layout.left} className={c.label}>Your email </Col>
                        <Col {...layout.right}>
                          <FormItem
                            hasFeedback
                            className={c.input}
                          >
                            {getFieldDecorator('email', {
                              rules: [{
                                required: true, type: 'email', message: 'Please enter valid email address',
                              }],
                            })(
                              <Input />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Fragment>
                  )
                }
                <Row>
                  <Col {...layout.left} className={c.label}>Message </Col>
                  <Col {...layout.right}>
                    <FormItem
                      hasFeedback
                      className={c.input}
                    >
                      {getFieldDecorator('message', {
                        rules: [{
                          required: true,
                          message: 'Please enter the message you want to send',
                        }, {
                          min: 1,
                          max: 4000,
                          message: 'Message should be between 1 and 4000 characters',
                        }],
                      })(
                        <TextArea autoSize={{ minRows: 4 }} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                {
                  !user.loggedIn && (
                    <Row>
                      <Col {...layout.offset}>
                        <FormItem className={c.input}>
                          {getFieldDecorator('validation', {
                            validateFirst: true,
                            rules: [{
                              required: true,
                              min: 1,
                              message: 'Please confirm that you are human',
                            }, {
                              validator: this.beValidation,
                            }],
                          })(
                            <ReCAPTCHA
                              sitekey={process.env.RECAPTCHA_CLIENT}
                              onChange={this.handleReCaptcha}
                              size="normal"
                              ref={(el) => {
                                this.recaptchaRef = el;
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )
                }
                <Row>
                  <Col {...layout.offset}>
                    <Button type="primary" htmlType="submit" className={c.submit} loading={submittingForm}>
                      Send message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToActions = {
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToActions)(Form.create()(ContactUsForm));

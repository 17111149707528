/**
 * Root Component
 */
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { hot } from 'react-hot-loader/root';
import initHistory from './history';
import AppContainer from './containers/App/App';
import Store from './store';
// Base stylesheet
import './main.less';

const store = Store.withHistory(typeof window !== 'undefined' && window.__INITIAL_STATE__);
const history = initHistory();

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContainer />
      </ConnectedRouter>
    </Provider>
  );
}

export default hot(App);

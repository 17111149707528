import { stringify } from 'qs';
import { normalize } from 'normalizr';

import api from '../helpers/api';
import {
  NOTIFICATION_LIST_REQUESTED,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_REMOVE,
} from '../reducers/notifications';
import { notificationsListSchema } from '../schemas';


export function listNotifications({ offset = 0, limit = 2 } = { offset: 0, limit: 2 }) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_LIST_REQUESTED });

    const queryObj = {
      offset,
      limit,
    };

    return api.get(`/user-messages?${stringify(queryObj)}`)
      .then((response) => {
        const notifications = normalize(response.items, notificationsListSchema);

        dispatch({
          type: NOTIFICATION_LIST_SUCCESS,
          payload: {
            items: notifications.entities.notification,
            ids: notifications.result,
            total: response.total,
          },
        });
      });
  };
}

export function removeNotification(id) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_REMOVE,
      payload: {
        id,
      },
    });

    return api.post(`/user-messages/${id}/hide`);
  };
}

export default {
  listNotifications,
  removeNotification,
};

import { stringify } from 'qs';

import api from '../helpers/api';
import {
  FEEDBACK_LIST_REQUESTED,
  FEEDBACK_LIST_SUCCESS,
} from '../reducers/feedback';

export function listFeedback({ offset, limit } = { offset: 0, limit: 2 }) {
  return (dispatch) => {
    dispatch({ type: FEEDBACK_LIST_REQUESTED });

    const queryObj = {
      offset,
      limit,
    };

    return api.get(`/feedback?${stringify(queryObj)}`)
      .then((response) => {
        dispatch({
          type: FEEDBACK_LIST_SUCCESS,
          payload: response,
        });
      });
  };
}

export default {
  listFeedback,
};

import { ADD_MESSAGE, CLEAR_MESSAGE } from '../reducers/message';

export const removeMessage = (messageKey) => ({
  type: CLEAR_MESSAGE,
  payload: {
    key: messageKey,
  },
});

const defaultDuration = 4.5;
let initialKey = 1;

export const addMessage = (content, duration = defaultDuration, type, onClose = () => null, key = initialKey++) => { // eslint-disable-line
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        key,
        content,
        duration,
        type,
      },
    });

    setTimeout((k) => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: { key: k },
      });
      onClose();
    }, duration * 1000, key);
  };
};

export default {
  removeMessage,
  success({
    content, duration, onClose, key,
  }) {
    return addMessage(content, duration, 'success', onClose, key);
  },
  error({
    content, duration, onClose, key,
  }) {
    return addMessage(content, duration, 'error', onClose, key);
  },
  warning({
    content, duration, onClose, key,
  }) {
    return addMessage(content, duration, 'warning', onClose, key);
  },
};

import {
  propEq,
  find,
} from 'ramda';

import { releasesByChart } from '../../actions/release';
import { chartList } from './common';

const chartNeeds = [
  (params, state, match) => {
    const chart = find(propEq('name', match.params.chartName), chartList);

    return releasesByChart(chart.key);
  },
];

export default {
  chartNeeds,
};

export {
  chartNeeds,
};

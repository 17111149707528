import React from 'react';
import PropType from 'prop-types';
import cx from 'classnames';
import c from './SheetTitle.less';

const SheetTitle = ({
  title, rightBlock, id, className,
}) => {
  return (
    <div className={cx(c.sectionTitle, className)} id={id}>
      <h3 className={c.sectionHeader}>{title}</h3>
      {rightBlock}
    </div>
  );
};

SheetTitle.propTypes = {
  title: PropType.string.isRequired,
  rightBlock: PropType.element,
  id: PropType.string,
  className: PropType.string,
};

SheetTitle.defaultProps = {
  rightBlock: null,
  id: null,
  className: null,
};

export default SheetTitle;

import React from 'react';
import c from './LoadingContainer.less';
import DiskIcon from './assets/compact-disc.svg';

export default function () {
  return (
    <div className={c.container}>
      <DiskIcon className={c.disk} />
      <p className={c.text}>Loading...</p>
    </div>
  );
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { Badge } from 'antd';

import CartIcon from './assets/shopping-cart-material.svg';
import { loadCartSummary } from '../../actions/shoppingCart';
import c from './CartIndicator.less';

class CartIndicator extends PureComponent {
  static propTypes = {
    summary: PropTypes.shape({
      data: PropTypes.shape({
        tracksAmount: PropTypes.number,
        totalPrice: PropTypes.number,
      }),
    }).isRequired,
    className: PropTypes.string,
    loadCartSummary: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  UNSAFE_componentWillMount() {
    if (this.props.user.loggedIn) {
      this.props.loadCartSummary();
    }
  }

  render() {
    const { className, summary: { data: { totalPrice, tracksAmount } } } = this.props;
    return (
      <Link to="/shop/checkout" className={classNames(className, c.wrapper)} aria-label="Checkout">
        <Badge dot={tracksAmount > 0} offset={[3, 0]}>
          <CartIcon />
        </Badge>
        {
          tracksAmount > 0 ? (
            <span className={classNames('visible-desktop', c.balance)}>$<strong>{totalPrice.toFixed(2)}</strong></span>
          ) : null
        }
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  summary: state.shoppingCart.summary,
});

const mapDispatchToProps = {
  loadCartSummary,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartIndicator));

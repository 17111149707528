import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Badge } from 'antd';

import { listNotifications } from '../../actions/notifications';

import PersonIcon from './assets/person-material.svg';

class UserNotifications extends Component {
  static propTypes = {
    listNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    const { app: { renderedComponent }, user: { loggedIn } } = this.props;

    if (renderedComponent !== 'Notifications' && loggedIn) {
      this.props.listNotifications();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname && nextProps.user.loggedIn) {
      this.props.listNotifications();
    }
  }

  render() {
    const { notifications, className } = this.props;

    return (
      <span className={className}>
        <Badge dot={notifications.total > 0}>
          <PersonIcon />
        </Badge>
      </span>
    );
  }
}

export default withRouter(connect(
  (store) => ({
    app: store.app,
    user: store.user,
    notifications: store.notifications,
  }),
  {
    listNotifications,
  }
)(UserNotifications));

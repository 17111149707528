import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import analytics from '../../helpers/analytics';
import ContactUsForm from '../ContactUsForm';
import c from './ContactUsModal.less';

export default class ContactUsModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultSubject: PropTypes.number,
  };

  static defaultProps = {
    defaultSubject: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible && prevProps.visible !== this.props.visible) {
      analytics
        .pageview('/shop/contact-us', {
          title: 'Contact us at Mp3Caprice',
        })
        .setActivePage('Contact Us');
    }
  }

  handleCloseModal = () => {
    this.props.onClose();
  };

  render() {
    const { visible, onClose } = this.props;

    return (
      <Modal
        wrapClassName={c.modal}
        visible={visible}
        title="Contact us"
        footer={null}
        onCancel={onClose}
        bodyStyle={{ padding: '13px 20px 30px' }}
        width={780}
      >
        {visible && <ContactUsForm onClickOk={this.handleCloseModal} defaultSubject={this.props.defaultSubject} />}
      </Modal>
    );
  }
}

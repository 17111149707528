const initialStore = {
  siderCollapsed: true,
  siderHidden: false,
  renderedComponent: null,
  status: 200,
  misc: { banner: {}, siteMessage: {} },
};

export const SIDER_COLLAPSE = 'SIDER_COLLAPSE';
export const SIDER_RESTORE = 'SIDER_RESTORE';
export const SITE_RENDERED = 'SITE_RENDERED';
export const SITE_DISPLAYED = 'SITE_DISPLAYED';
export const CLEAR_NOT_FOUND = 'CLEAR_NOT_FOUND';
export const SET_NOT_FOUND = 'SET_NOT_FOUND';
export const SIDER_HIDE = 'SIDER_HIDE';
export const SIDER_SHOW = 'SIDER_SHOW';
export const MISC_LOADED = 'MISC_LOADED';

export default function (store = initialStore, action) {
  switch (action.type) {
    case SITE_RENDERED: {
      return {
        ...store,
        renderedComponent: action.component,
      };
    }

    case SITE_DISPLAYED: {
      return {
        ...store,
        renderedComponent: undefined,
      };
    }

    case SET_NOT_FOUND: {
      return {
        ...store,
        status: 404,
      };
    }

    case CLEAR_NOT_FOUND: {
      return {
        ...store,
        status: 200,
      };
    }

    case SIDER_COLLAPSE: {
      return {
        ...store,
        siderCollapsed: true,
      };
    }
    case SIDER_RESTORE: {
      return {
        ...store,
        siderCollapsed: false,
      };
    }
    case SIDER_HIDE: {
      return {
        ...store,
        siderHidden: true,
      };
    }
    case SIDER_SHOW: {
      return {
        ...store,
        siderHidden: false,
      };
    }
    case MISC_LOADED: {
      return {
        ...store,
        misc: action.payload,
      };
    }
    default:
      return store;
  }
}

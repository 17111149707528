const defaultPagination = {
  rows: 4,
  maxCols: 6,
  portion: 1,
  totalEntities: 0,
};

export {
  defaultPagination,
};

export default {
  defaultPagination,
};

import { normalize } from 'normalizr';
import { stringify } from 'qs';

import api from '../helpers/api';
import { trackListSchema } from '../schemas';
import { TRACK_SUCCESS, ARTIST_TRACKS_SUCCESS } from '../reducers/track';

export function tracksByArtist(artistId, limit = 10, offset = 0, sortPopularity = false) {
  return (dispatch) => {
    const searchQuery = {
      artist: artistId,
      limit,
      offset,
      ...(sortPopularity ? {
        sort: 'popularity',
      } : {}),
    };

    return api.get(`/tracks?${stringify(searchQuery)}`)
      .then((response) => {
        const data = normalize(response.items, trackListSchema);

        dispatch([{
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }, {
          type: ARTIST_TRACKS_SUCCESS,
          payload: {
            artistId,
            total: response.total,
            trackList: data.result,
          },
        }]);
      });
  };
}

export default {
  tracksByArtist,
};

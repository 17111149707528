import { combineReducers } from 'redux';

export const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_SUCCESS_RELEASES = 'SEARCH_SUCCESS_RELEASES';
export const SEARCH_SUCCESS_ARTISTS = 'SEARCH_SUCCESS_ARTISTS';
export const SEARCH_SUCCESS_TRACKS = 'SEARCH_SUCCESS_TRACKS';
export const SEARCH_SUCCESS_SERIES = 'SEARCH_SUCCESS_SERIES';
export const SEARCH_SUCCESS_GENRES = 'SEARCH_SUCCESS_GENRES';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CLEAR_RELEASES = 'SEARCH_CLEAR_RELEASES';
export const SEARCH_CLEAR_ARTISTS = 'SEARCH_CLEAR_ARTISTS';
export const SEARCH_CLEAR_TRACKS = 'SEARCH_CLEAR_TRACKS';

const initialStore = {
  loading: false,
  error: false,
};

export function fetching(store = initialStore, action) {
  switch (action.type) {
    case SEARCH_REQUESTED: {
      return {
        loading: true,
        error: false,
      };
    }
    case SEARCH_SUCCESS: {
      return {
        loading: false,
        error: false,
      };
    }
    case SEARCH_CLEAR: {
      return initialStore;
    }

    default:
      return store;
  }
}

const releasesInitStore = { items: [], total: 0 };

export function releases(store = releasesInitStore, action) {
  switch (action.type) {
    case SEARCH_SUCCESS_RELEASES: {
      return action.payload;
    }

    case SEARCH_CLEAR:
    case SEARCH_CLEAR_RELEASES: {
      return releasesInitStore;
    }

    default:
      return store;
  }
}

const artistsInitStore = { items: [], total: 0 };

export function artists(store = artistsInitStore, action) {
  switch (action.type) {
    case SEARCH_SUCCESS_ARTISTS: {
      return action.payload;
    }

    case SEARCH_CLEAR:
    case SEARCH_CLEAR_ARTISTS: {
      return releasesInitStore;
    }

    default:
      return store;
  }
}

const tracksInitStore = { items: [], total: 0 };

export function tracks(store = tracksInitStore, action) {
  switch (action.type) {
    case SEARCH_SUCCESS_TRACKS: {
      return action.payload;
    }

    case SEARCH_CLEAR:
    case SEARCH_CLEAR_TRACKS: {
      return releasesInitStore;
    }

    default:
      return store;
  }
}

const seriesInitStore = { items: [], total: 0 };

export function series(store = seriesInitStore, action) {
  switch (action.type) {
    case SEARCH_SUCCESS_SERIES: {
      return action.payload;
    }

    case SEARCH_CLEAR: {
      return seriesInitStore;
    }

    default:
      return store;
  }
}

const genresInitStore = { items: [], total: 0 };

export function genres(store = genresInitStore, action) {
  switch (action.type) {
    case SEARCH_SUCCESS_GENRES: {
      return action.payload;
    }

    case SEARCH_CLEAR: {
      return genresInitStore;
    }

    default:
      return store;
  }
}

export default combineReducers({
  fetching,
  data: combineReducers({
    releases,
    artists,
    tracks,
    series,
    genres,
  }),
});

const initialStore = {
  playingTrack: null,
  playingMode: 'single',
  isPaused: true,
};

export const TRACK_START_PLAYING = 'TRACK_START_PLAYING';
export const TRACK_STOP_PLAYING = 'TRACK_STOP_PLAYING';
export const TRACK_PAUSE_PLAYING = 'TRACK_PAUSE_PLAYING';
export const TRACK_RESUME_PLAYING = 'TRACK_RESUME_PLAYING';
export const CHANGE_PLAYER_MODE = 'CHANGE_PLAYER_MODE';

export default function (store = initialStore, action) {
  switch (action.type) {
    case TRACK_START_PLAYING: {
      return {
        ...store,
        playingTrack: action.payload.id,
        isPaused: false,
      };
    }
    case TRACK_STOP_PLAYING: {
      return {
        ...store,
        isPaused: true,
        playingTrack: null,
      };
    }
    case TRACK_PAUSE_PLAYING: {
      return {
        ...store,
        isPaused: true,
      };
    }
    case TRACK_RESUME_PLAYING: {
      return {
        ...store,
        isPaused: false,
      };
    }
    case CHANGE_PLAYER_MODE: {
      return {
        ...store,
        playingMode: action.payload,
      };
    }
    default:
      return store;
  }
}

import { combineReducers } from 'redux';
import { mergeDeepLeft, propOr, union } from 'ramda';

export const TRACK_SUCCESS = 'TRACK_SUCCESS';
export const ARTIST_TRACKS_SUCCESS = 'ARTIST_TRACKS_SUCCESS';

export function list(store = {}, action) {
  switch (action.type) {
    case TRACK_SUCCESS:
      return {
        ...store,
        ...action.payload,
      };
    default:
      return store;
  }
}

export function byArtist(store = { ids: {}, total: {} }, action) {
  switch (action.type) {
    case ARTIST_TRACKS_SUCCESS: {
      const { artistId, total, trackList } = action.payload;

      return mergeDeepLeft({
        total: {
          [artistId]: total,
        },
        ids: {
          [artistId]: union(propOr([], artistId, store.ids), trackList),
        },
      }, store);
    }
    default:
      return store;
  }
}

export default combineReducers({
  list,
  byArtist,
});

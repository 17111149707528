import React from 'react';
import loadable from '@loadable/component';
import LoadingContainer from '../LoadingContainer';

export const UniversalContainersLoader = loadable((props) => import(`../../containers/${props.page}`), {
  fallback: <LoadingContainer />,
});

export default {
  UniversalContainersLoader,
};

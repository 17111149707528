export const FEEDBACK_LIST_REQUESTED = 'FEEDBACK_LIST_REQUESTED';
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS';
// export const FEEDBACK_LIST_CLEAR = 'FEEDBACK_LIST_CLEAR';

const initialState = {
  data: [],
  total: 0,
  loading: false,
  loaded: false,
};

export function feedbackList(store = initialState, action) {
  switch (action.type) {
    case FEEDBACK_LIST_REQUESTED:
      return {
        ...store,
        loading: true,
        loaded: false,
      };
    case FEEDBACK_LIST_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload.items,
        total: action.payload.total,
      };
    default:
      return store;
  }
}

export const TOKEN_VALID = 'TOKEN_VALID';
export const TOKEN_INVALID = 'TOKEN_INVALID';

export default function validToken(store = { isValid: false }, action) {
  switch (action.type) {
    case TOKEN_VALID: {
      return {
        isValid: true,
      };
    }
    case TOKEN_INVALID: {
      return {
        isValid: false,
      };
    }
    default:
      return store;
  }
}

import {
  compose, defaultTo, flip, multiply, subtract,
} from 'ramda';

import { requestsPerPage } from './common';
import { listRequests } from '../../actions/musicRequests';

const musicRequestsNeeds = [
  (params) => {
    const query = {
      limit: requestsPerPage,
      offset: compose(multiply(requestsPerPage), flip(subtract)(1), defaultTo(1))(params.page),
    };

    return listRequests(query);
  },
];

export default musicRequestsNeeds;

export {
  musicRequestsNeeds,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Menu, { LINK_TYPES } from '../Menu';
import routes from '../../routes';

export default function MainMenu(props) {
  const { menuPromoItem } = useSelector((state) => state.app.misc);
  const findComponent = (name) => routes.find((r) => r.name === name).routeComponent;
  const menuItems = [
    {
      key: 'home',
      url: '/',
      title: 'Home',
      exact: true,
      component: findComponent('Home'),
    },
    {
      key: 'lastAdded',
      url: '/last-added',
      title: 'Last Added',
      component: findComponent('LastAdded'),
    },
    {
      key: 'chart',
      url: '/chart/caprice',
      title: 'World Charts',
      component: findComponent('Chart'),
    },
    {
      key: 'series',
      url: '/series',
      title: 'Music Series',
      component: findComponent('Series'),
    },
    {
      key: 'genre',
      url: '/genre',
      title: 'Music Genres',
      component: findComponent('Genre'),
    },
  ];

  if (menuPromoItem) {
    menuItems.push({
      key: menuPromoItem.itemName,
      url: new URL(menuPromoItem.itemUrl).pathname,
      title: menuPromoItem.itemName,
      type: LINK_TYPES.PROMO,
    });
  }

  return (
    <Menu items={menuItems} onItemClick={props.onItemClick} onItemHover={props.onItemHover} />
  );
}

MainMenu.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  onItemHover: PropTypes.func,
};

MainMenu.defaultProps = {
  onItemHover: () => null,
};

export const MUSIC_REQUEST_LIST_REQUESTED = 'MUSIC_REQUEST_LIST_REQUESTED';
export const MUSIC_REQUEST_LIST_SUCCESS = 'MUSIC_REQUEST_LIST_SUCCESS';
export const MUSIC_REQUEST_LIST_CLEAR = 'MUSIC_REQUEST_LIST_CLEAR';

const initialState = {
  data: [],
  total: 0,
  loading: false,
};

export default function musicRequests(store = initialState, action) {
  switch (action.type) {
    case MUSIC_REQUEST_LIST_REQUESTED:
      return {
        ...store,
        loading: true,
      };
    case MUSIC_REQUEST_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload.items,
        total: action.payload.total,
      };
    case MUSIC_REQUEST_LIST_CLEAR:
      return initialState;
    default:
      return store;
  }
}

import { reviewByReleaseId } from '../../actions/review';
import { releaseById, getMoreReleasesByArtist, getRecommendedReleases } from '../../actions/release';

const releaseNeeds = [
  (params) => {
    return releaseById(params.id);
  },
  (params, store) => {
    return store.releases.list.data[params.id] && reviewByReleaseId({ releaseId: params.id, limit: 50 });
  },
  (params, store) => {
    const release = store.releases.list.data[params.id];
    return release && release.artist > 0 && getMoreReleasesByArtist(release.artist, params.id);
  },
  (params, store) => {
    return store.releases.list.data[params.id] && getRecommendedReleases({ releaseId: params.id });
  },
];

export {
  releaseNeeds,
};

export default releaseNeeds;

export const defaultPagination = {
  rows: 1,
  maxCols: 6,
  portion: 1,
  totalEntities: 0,
};

export const releaseBlocks = [
  { id: 'albums', title: 'Albums', types: ['Album'] },
  { id: 'singles', title: 'Singles & Remixes', types: ['Single', 'Remix'] },
  { id: 'compilations', title: 'Compilations & Concerts', types: ['Artist_Compilation', 'Live'] },
  { id: 'soundtracks', title: 'Soundtracks', types: ['Soundtrack'] },
];

export default {
  defaultPagination,
  releaseBlocks,
};

import { parse } from 'qs';
import { getPurchasedReleases } from '../../actions/release';
import { itemsPerPage } from './common';

const purchasedNeeds = [
  ({ page = 1 }, state, match, query) => {
    const { qd } = parse(query);

    return getPurchasedReleases({
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
      searchQuery: qd && qd.length >= 2 ? qd : null,
    });
  },
];

export default {
  purchasedNeeds,
};

export {
  purchasedNeeds,
};

import { combineReducers } from 'redux';

export const USER_REQUESTED = 'USER_REQUESTED';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';
export const USER_CLEAR = 'USER_CLEAR';

const initialProfile = { emailVerified: true, birthdayDate: null, userEmail: null };

function profile(store = initialProfile, { type, payload }) {
  switch (type) {
    case USER_SUCCESS:
      return {
        ...store,
        ...payload.profile,
      };
    case USER_FAIL:
    case USER_CLEAR:
      return initialProfile;
    default:
      return store;
  }
}

function emailPrefs(store = {}, { type, payload }) {
  switch (type) {
    case USER_SUCCESS:
      return {
        ...store,
        ...payload.emailPrefs,
      };
    case USER_FAIL:
    case USER_CLEAR:
      return {};
    default:
      return store;
  }
}

export const BALANCE_SUCCESS = 'BALANCE_SUCCESS';

function balance(store = 0, { type, payload }) {
  switch (type) {
    case BALANCE_SUCCESS:
      return payload.balance;
    case USER_FAIL:
    case USER_CLEAR:
      return 0;
    default:
      return store;
  }
}

function loggedIn(store = false, { type }) {
  switch (type) {
    case USER_SUCCESS:
      return true;
    case USER_FAIL:
    case USER_CLEAR:
      return false;
    default:
      return store;
  }
}

const userInitialStore = {
  loading: false,
  loggedIn: false,
  error: false,
  errorBody: null,
};

function fetching(store = userInitialStore, action) {
  switch (action.type) {
    case USER_REQUESTED:
      return {
        ...store,
        loading: true,
        error: false,
        errorBody: null,
      };
    case USER_SUCCESS:
      return {
        loading: false,
        error: false,
        errorBody: null,
      };
    case USER_CLEAR:
      return userInitialStore;
    case USER_FAIL:
      return {
        loading: false,
        error: true,
        errorBody: action.payload,
      };
    default:
      return store;
  }
}

export const ACCOUNT_ACTIVATION_SUCCESS = 'ACCOUNT_ACTIVATION_SUCCESS';
export const ACCOUNT_ACTIVATION_FAIL = 'ACCOUNT_ACTIVATION_FAIL';

export function activation(store = { status: false, error: null }, action) {
  switch (action.type) {
    case ACCOUNT_ACTIVATION_SUCCESS: {
      return {
        status: true,
        error: null,
      };
    }
    case ACCOUNT_ACTIVATION_FAIL: {
      return {
        status: false,
        error: action.payload,
      };
    }
    default: return store;
  }
}

export default combineReducers({
  fetching,
  loggedIn,
  activation,
  data: combineReducers({
    profile,
    emailPrefs,
    balance,
  }),
});

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';

const loginInitialStore = {
  loading: false,
  error: false,
};

export function auth(store = loginInitialStore, action) {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        error: null,
      };
    case LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case LOGIN_CLEAR: {
      return loginInitialStore;
    }
    default:
      return store;
  }
}

export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_CLEAR = 'SIGNUP_CLEAR';

const signupInitialStore = {
  loading: false,
  error: false,
  data: {},
};

export function signup(store = signupInitialStore, action) {
  switch (action.type) {
    case SIGNUP_REQUESTED:
      return {
        ...store,
        loading: true,
        error: false,
      };
    case SIGNUP_SUCCESS:
      return {
        loading: false,
        error: false,
        data: {
          success: true,
        },
      };
    case SIGNUP_FAIL:
      return {
        loading: false,
        error: true,
        data: action.payload,
      };
    case SIGNUP_CLEAR: {
      return signupInitialStore;
    }
    default:
      return store;
  }
}

import { combineReducers } from 'redux';
import {
  mergeDeepLeft,
  union,
  propOr,
} from 'ramda';

export const RELEASES_REQUESTED = 'RELEASES_REQUESTED';
export const RELEASES_SUCCESS = 'RELEASES_SUCCESS';
export const RELEASES_FAIL = 'RELEASES_FAIL';

const initialStore = {
  loading: false,
  error: false,
  data: {},
};

export function list(store = initialStore, action) {
  switch (action.type) {
    case RELEASES_REQUESTED: {
      return {
        ...store,
        loading: true,
        error: false,
      };
    }
    case RELEASES_SUCCESS: {
      const newData = { ...store.data };

      if (action.payload) {
        Object.keys(action.payload).forEach((id) => {
          newData[id] = {
            ...store.data[id],
            ...action.payload[id],
          };
        });
      }

      return {
        loading: false,
        error: false,
        data: newData,
      };
    }
    case RELEASES_FAIL: {
      return {
        ...store,
        loading: false,
        error: true,
      };
    }
    default:
      return store;
  }
}

export const LAST_ADDED_RELEASES_SUCCESS = 'LAST_ADDED_RELEASES_SUCCESS';
export const CLEAR_LAST_ADDED_RELEASES = 'CLEAR_LAST_ADDED_RELEASES';

const initialByDate = {
  total: {}, ids: {},
};

export function byDate(store = initialByDate, { type, payload }) {
  switch (type) {
    case LAST_ADDED_RELEASES_SUCCESS: {
      const { total, date, releaseList } = payload;
      return {
        total: {
          ...store.total,
          [date]: total,
        },
        ids: {
          ...store.ids,
          [date]: releaseList,
        },
      };
    }
    case CLEAR_LAST_ADDED_RELEASES:
      return initialByDate;
    default:
      return store;
  }
}

export const PROMO_RELEASES_SUCCESS = 'PROMO_RELEASES_SUCCESS';

export function promoReleaseIdList(store = [], action) {
  switch (action.type) {
    case PROMO_RELEASES_SUCCESS:
      return action.payload;
    default:
      return store;
  }
}

export const ARTIST_RELEASES_SUCCESS = 'ARTIST_RELEASES_SUCCESS';

export function byArtist(store = { total: {}, ids: {} }, action) {
  switch (action.type) {
    case ARTIST_RELEASES_SUCCESS: {
      const {
        type, total, artistId, releaseList,
      } = action.payload;
      return {
        total: {
          ...store.total,
          [artistId]: {
            ...store.total[artistId],
            [type]: total,
          },
        },
        ids: {
          ...store.ids,
          [artistId]: {
            ...store.ids[artistId],
            [type]: union(propOr([], type, propOr({}, artistId, store.ids)), releaseList),
          },
        },
      };
    }
    default:
      return store;
  }
}


export const ARTIST_MORE_RELEASES_SUCCESS = 'ARTIST_MORE_RELEASES_SUCCESS';

export function moreByArtist(store = { total: {}, ids: {} }, action) {
  switch (action.type) {
    case ARTIST_MORE_RELEASES_SUCCESS: {
      const { total, artistId, releaseList } = action.payload;
      return {
        total: {
          ...store.total,
          [artistId]: total,
        },
        ids: {
          ...store.ids,
          [artistId]: releaseList,
        },
      };
    }
    default:
      return store;
  }
}

export const GENRE_RELEASES_SUCCESS = 'GENRE_RELEASES_SUCCESS';

export function byGenre(store = { total: {}, ids: {} }, { type, payload }) {
  switch (type) {
    case GENRE_RELEASES_SUCCESS: {
      const { genreId, total, releaseList } = payload;
      return mergeDeepLeft({
        total: {
          [genreId]: total,
        },
        ids: {
          [genreId]: releaseList,
        },
      }, store);
    }
    default:
      return store;
  }
}

export const SERIES_RELEASES_SUCCESS = 'SERIES_RELEASES_SUCCESS';
export const SERIES_RELEASES_CLEAR = 'SERIES_RELEASES_CLEAR';

export function bySeries(store = { total: {}, ids: {} }, { type, payload }) {
  switch (type) {
    case SERIES_RELEASES_SUCCESS: {
      const { seriesId, total, releaseList } = payload;
      return mergeDeepLeft({
        total: {
          [seriesId]: total,
        },
        ids: {
          [seriesId]: releaseList,
        },
      }, store);
    }
    case SERIES_RELEASES_CLEAR: {
      return mergeDeepLeft({
        ids: {
          [payload.seriesId]: [],
        },
      }, store);
    }
    default:
      return store;
  }
}

export const CHART_RELEASES_SUCCESS = 'CHART_RELEASES_SUCCESS';

export function byChart(store = { total: {}, ids: {} }, action) {
  switch (action.type) {
    case CHART_RELEASES_SUCCESS: {
      const { chartType, total, releaseList } = action.payload;
      return mergeDeepLeft({
        total: {
          [chartType]: total,
        },
        ids: {
          [chartType]: union(propOr([], chartType, store.ids), releaseList),
        },
      }, store);
    }
    default:
      return store;
  }
}

export const HOME_LAST_ADDED_RELEASES_SUCCESS = 'HOME_LAST_ADDED_RELEASES_SUCCESS';

export function homeLastAdded(store = { total: 0, ids: [] }, action) {
  switch (action.type) {
    case HOME_LAST_ADDED_RELEASES_SUCCESS: {
      const { ids, total } = action.payload;
      return {
        total,
        ids,
      };
    }
    default:
      return store;
  }
}

export const HOME_RECOMMENDED_RELEASES_SUCCESS = 'HOME_RECOMMENDED_RELEASES_SUCCESS';
export const HOME_RECOMMENDED_RELEASES_CLEAR = 'HOME_RECOMMENDED_RELEASES_CLEAR';

export function homeRecommended(store = [], action) {
  switch (action.type) {
    case HOME_RECOMMENDED_RELEASES_SUCCESS: {
      return action.payload;
    }
    case HOME_RECOMMENDED_RELEASES_CLEAR: {
      return [];
    }
    default:
      return store;
  }
}

export const RECOMMENDED_RELEASES_REQUESTED = 'RECOMMENDED_RELEASES_REQUESTED';
export const RECOMMENDED_RELEASES_SUCCESS = 'RECOMMENDED_RELEASES_SUCCESS';

const initialStateForRecommended = {
  total: 0,
  data: {},
  loading: false,
};

export function recommended(store = initialStateForRecommended, action) {
  switch (action.type) {
    case RECOMMENDED_RELEASES_REQUESTED: {
      return {
        ...store,
        loading: true,
      };
    }
    case RECOMMENDED_RELEASES_SUCCESS: {
      const { ids, releaseId, total } = action.payload;
      return {
        ...store,
        data: {
          ...store.data,
          [releaseId]: ids,
        },
        loading: false,
        total,
      };
    }
    default:
      return store;
  }
}

export const PURCHASED_RELEASES_REQUESTED = 'PURCHASED_RELEASES_REQUESTED';
export const PURCHASED_RELEASES_SUCCESS = 'PURCHASED_RELEASES_SUCCESS';
export const PURCHASED_RELEASES_FAILED = 'PURCHASED_RELEASES_FAILED';

const initialStateForPurchased = {
  total: 0,
  data: [],
  loading: false,
  error: false,
};

export function purchased(store = initialStateForPurchased, { type, payload } = { payload: {} }) {
  switch (type) {
    case PURCHASED_RELEASES_REQUESTED: {
      return {
        ...store,
        loading: true,
        error: false,
      };
    }
    case PURCHASED_RELEASES_SUCCESS: {
      const { ids, total } = payload;
      return {
        data: ids,
        loading: false,
        total,
      };
    }
    case PURCHASED_RELEASES_FAILED: {
      return {
        ...store,
        loading: false,
        error: payload.error,
      };
    }
    default:
      return store;
  }
}

export default combineReducers({
  list,
  byDate,
  promoReleaseIdList,
  homeLastAdded,
  byArtist,
  byGenre,
  bySeries,
  byChart,
  moreByArtist,
  recommended,
  purchased,
  homeRecommended,
});

import { parse } from 'qs';
import { autocomplete } from '../../actions/search';

const perPage = 6;
const perPageByType = 20;

const searchNeeds = [
  (params, state, match, query) => {
    const { q, page } = parse(query);
    const { type } = match.params;

    let pagination;

    if (type) {
      pagination = {
        offset: page ? page * perPage : 0,
        limit: type ? perPageByType : perPage,
      };
    }

    if (q && q.length > 0) {
      return autocomplete(q, type, pagination);
    }

    return { type: '' };
  },
];

export {
  searchNeeds,
};

export default searchNeeds;

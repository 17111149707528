import { stringify } from 'qs';
import api from '../helpers/api';

import { DIRECT_PAYMENT_SUCCESS, DIRECT_PAYMENT_FAILED, DIRECT_PAYMENT_CLEAR } from '../reducers/directPayment';

export function getDirectPayment(token) {
  return (dispatch) => {
    return api.post(`/payment?${stringify({
      type: 'direct',
    })}`, {}, (token ? { 'X-Auth-Token': token } : {}))
      .then((response) => {
        if (response.creditCardForm) {
          return api.get(`/payment/${response.creditCardForm.orderId}`, {
            'X-Auth-Token': token,
          })
            .then(({ paymentAmount }) => {
              dispatch({
                type: DIRECT_PAYMENT_SUCCESS,
                payload: {
                  ...response,
                  creditCardForm: {
                    ...response.creditCardForm,
                    paymentAmount,
                  },
                },
              });
            });
        }

        if (response.requestResult) {
          dispatch({
            type: DIRECT_PAYMENT_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: DIRECT_PAYMENT_FAILED,
          });
        }

        return Promise.resolve();
      }, ({ body }) => {
        dispatch({
          type: DIRECT_PAYMENT_FAILED,
          payload: body,
        });
      });
  };
}

export const clearDirectPayment = () => ({
  type: DIRECT_PAYMENT_CLEAR,
});

export default {
  getDirectPayment,
  clearDirectPayment,
};

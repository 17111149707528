export const SERIES_REQUESTED = 'SERIES_REQUESTED';
export const SERIES_SUCCESS = 'SERIES_SUCCESS';
export const SERIES_ERROR = 'SERIES_ERROR';

const initialState = {
  data: {},
  loading: false,
  error: false,
};
export function series(store = initialState, action) {
  switch (action.type) {
    case SERIES_REQUESTED:
      return {
        ...store,
        loading: true,
        error: false,
      };
    case SERIES_SUCCESS:
      return {
        loading: false,
        error: false,
        data: {
          ...store.data,
          ...action.payload,
        },
      };
    case SERIES_ERROR:
      return {
        data: {},
        loading: false,
        error: true,
      };
    default:
      return store;
  }
}

import api from '../helpers/api';
import { RELEASE_DATES_REQUESTED, RELEASE_DATES_SUCCESS } from '../reducers/releaseCalendar';

export function getReleaseDates() {
  return (dispatch) => {
    dispatch({ type: RELEASE_DATES_REQUESTED });

    return api.get('/version/db/catalog')
      .then((response) => {
        dispatch({
          type: RELEASE_DATES_SUCCESS,
          payload: response.map((r) => r.date),
        });
      });
  };
}

export default {
  getReleaseDates,
};

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, Badge } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import NotificationsIcon from 'material-design-icons/social/svg/production/ic_notifications_none_24px.svg';
import { logout } from 'actions/user';

import PersonEditIcon from './assets/icon-person-edit-outlined.svg';

import Balance from '../Balance';
import c from './UserMenu.less';

class UserMenu extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
    userPrice: PropTypes.shape({
      coefficient: PropTypes.number,
      endDate: PropTypes.string,
    }).isRequired,
  };

  state = {
    popoverVisible: false,
  };

  UNSAFE_componentWillReceiveProps() {
    if (this.state.popoverVisible) {
      this.handleHidePopover();
    }
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.handleHidePopover();
    this.props.logout();
  };

  handleHidePopover = () => {
    this.handleVisibleChange(false);
  };

  handleVisibleChange = (visible) => {
    this.setState({ popoverVisible: visible });
  };

  renderContent() {
    const { user: { data: { balance, profile } }, notifications, userPrice } = this.props;

    return (
      <div>
        {
          userPrice.coefficient && (
            <div className={c.discount}>
              {100 - userPrice.coefficient * 100}% price discount is running
              {userPrice.endDate && ` until ${moment(userPrice.endDate).format('DD MMM, YYYY')}`}
            </div>
          )
        }
        <div className={c.topSection}>
          <div className={c.titles}>
            <Link className={c.username} to="/shop/profile">{profile.username}</Link>
          </div>
          <div className={c.rightBlock}>
            <Link to="/shop/profile">
              <PersonEditIcon className={c.editIcon} />
            </Link>
            <Link
              className={cx(c.notifications)}
              to="/shop/notifications"
            >
              <Badge count={notifications.total} offset={[-3, 5]}>
                <NotificationsIcon />
              </Badge>
            </Link>
          </div>
          <span className={c.email}>{profile.userEmail}</span>
        </div>
        <div className={c.balanceSection}>
          <div className={c.balance}>
            <span className={c.title}>My balance</span>
            <Link to="/shop/account-statements" className={cx(c.value, { [c.negativeBalance]: balance < 0 })}>
              <Balance value={balance} />
            </Link>
            <Link className={c.redeemCoupon} to="/shop/coupon/redeem">
              Redeem coupon
            </Link>
          </div>
          <Link className={c.topUp} to="/shop/top-up">
            Top up
          </Link>
        </div>
        <ul className={c.menuSection}>
          <li><Link to="/shop/downloads">My music</Link></li>
          <li><Link to="/shop/coupon/purchase">Purchase coupon</Link></li>
          <li><Link to="/shop/music-request">Send music request</Link></li>
          <li><a href="/logout" onClick={this.handleLogout}>Sign out</a></li>
        </ul>
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const { popoverVisible } = this.state;

    return (
      <Popover
        placement="bottomRight"
        content={this.renderContent()}
        arrowPointAtCenter
        visible={popoverVisible}
        onVisibleChange={this.handleVisibleChange}
        trigger="click"
        overlayClassName={c.userMenuPopover}
      >
        {children}
      </Popover>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  notifications: store.notifications,
  userPrice: store.app.misc.userPrice || {},
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMenu));

import { defaultTo, subtract } from 'ramda';

import { getGenreById, getGenreList } from '../../actions/genre';
import { releasesByGenre } from '../../actions/release';
import { defaultPagination } from '../../components/Pagination/common';

const genreNeeds = [
  (params) => getGenreById(params.id),
  (params, store) => {
    if (store.genres.data[params.id]) {
      return releasesByGenre(params.id, {
        limit: defaultPagination.rows * defaultPagination.maxCols,
        offset: defaultPagination.rows * defaultPagination.maxCols * subtract(parseInt(defaultTo(1, params.page), 10), 1),
      });
    }

    return { type: '' };
  },
];

const genreListNeeds = [
  () => getGenreList(),
];

export {
  genreNeeds,
  genreListNeeds,
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from 'material-design-icons/action/svg/production/ic_search_48px.svg';
import CancelIcon from 'material-design-icons/navigation/svg/production/ic_cancel_48px.svg';
import DiskIcon from './assets/compact-disc.svg';

import c from './Error.less';
import { smoothScroll } from '../../helpers/view';
import analytics from '../../helpers/analytics';

class ErrorComponent extends Component {
  static propTypes = {
    errorCode: PropTypes.oneOf(['404', '503']).isRequired,
    errorTitle: PropTypes.string,
  };

  static defaultProps = {
    errorTitle: null,
  };

  componentDidMount() {
    const { errorCode } = this.props;

    smoothScroll();

    analytics
      .pageview(`/error/${errorCode}`)
      .setActivePage('Error');
  }

  render() {
    const { errorCode, errorTitle } = this.props;
    let num = '';
    let title = '';
    let descr = '';
    let icon = null;

    switch (errorCode) {
      case '404': {
        num = (<Fragment>4 <DiskIcon className={c.disk} /> 4</Fragment>);
        title = errorTitle || 'Ooops, page is not found!';
        icon = <SearchIcon className={c.searchIcon} />;
        break;
      }

      case '503': {
        num = (<Fragment>5 <DiskIcon className={c.disk} /> 3</Fragment>);
        title = errorTitle || 'Sorry, service unavailable :(';
        descr = (
          <span>Something went wrong, please <a href={window.location.href}>refresh page</a>...</span>
        );
        icon = <CancelIcon className={c.cancelIcon} />;
        break;
      }

      default: {
        //
      }
    }
    return (
      <div className={c.container}>
        <div className={c.num}>{num}</div>
        <div className={c.title}>{title}</div>
        <div className={c.descr}>{descr}</div>
        {icon}
      </div>
    );
  }
}

export default ErrorComponent;

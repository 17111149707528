export const DIRECT_PAYMENT_SUCCESS = 'DIRECT_PAYMENT_SUCCESS';
export const DIRECT_PAYMENT_FAILED = 'DIRECT_PAYMENT_FAILED';
export const DIRECT_PAYMENT_CLEAR = 'DIRECT_PAYMENT_CLEAR';

const initialStore = {
  error: false,
  data: {},
};

export default function directPayment(store = initialStore, action) {
  switch (action.type) {
    case DIRECT_PAYMENT_SUCCESS: {
      return {
        error: false,
        data: action.payload,
      };
    }

    case DIRECT_PAYMENT_FAILED: {
      return {
        error: true,
        data: {},
      };
    }

    case DIRECT_PAYMENT_CLEAR: {
      return initialStore;
    }

    default:
      return store;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Error from '../Error';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (<Error errorCode="503" />);
    }

    return this.props.children;
  }
}

import { listNotifications } from '../../actions/notifications';

const notificationsNeeds = [
  () => listNotifications({ offset: 0, limit: 20 }),
];

export default notificationsNeeds;

export {
  notificationsNeeds,
};

import cookies from 'react-cookies';

const cookieDomain = `.${process.env.SITE_DOMAIN}`;
const SID_PATTERN = /^[a-zA-Z0-9_\-.]{1,50}$/;

export default class Referrer {
  static set pid(pid) {
    cookies.save('pid', pid, {
      maxAge: 1209600,
      path: '/',
      domain: cookieDomain,
    });
  }

  static get pid() { // eslint-disable-line
    return cookies.load('pid');
  }

  static set subId(subId) {
    let subIdValue = 'default';

    if (subId && SID_PATTERN.test(subId)) {
      subIdValue = subId;
    }

    cookies.save('subid', subIdValue, {
      maxAge: 1209600,
      path: '/',
      domain: cookieDomain,
    });
  }

  static get subId() { // eslint-disable-line
    return cookies.load('subid');
  }

  static set site(referrer) {
    if (referrer && referrer.length > 0 && !referrer.includes(process.env.SITE_DOMAIN)) {
      cookies.save('ref-site', referrer, {
        maxAge: 31104000,
        path: '/',
        domain: cookieDomain,
      });
    }
  }

  static get site() { // eslint-disable-line
    return cookies.load('ref-site');
  }

  static clean(keys = ['pid', 'subid', 'ref-site']) {
    let keysToClean = keys;

    if (typeof keys === 'string') {
      keysToClean = [keys];
    }

    keysToClean.forEach((key) => {
      cookies.remove(key, {
        path: '/',
        domain: cookieDomain,
      });
    });
  }
}

import { normalize } from 'normalizr';
import api from '../helpers/api';
import { artistSchema } from '../schemas';
import { ARTIST_REQUESTED, ARTIST_SUCCESS, ARTIST_ERROR } from '../reducers/artist';

export function artistById(id) {
  return (dispatch) => {
    dispatch({ type: ARTIST_REQUESTED });
    return api.get(`/artists/${id}`)
      .then((response) => {
        const data = normalize(response, artistSchema);
        dispatch({
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        });
      })
      .catch(() => {
        dispatch({
          type: ARTIST_ERROR,
        });
      });
  };
}

export default {
  artistById,
};

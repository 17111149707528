import { uniq } from 'ramda';

export const NOTIFICATION_LIST_REQUESTED = 'NOTIFICATION_LIST_REQUESTED';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_CLEAR = 'NOTIFICATION_LIST_CLEAR';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

const initialState = {
  data: {},
  ids: [],
  total: 0,
  loading: false,
};

export function notificationList(store = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_LIST_REQUESTED:
      return {
        ...store,
        loading: true,
      };
    case NOTIFICATION_LIST_SUCCESS:
      return {
        loading: false,
        total: action.payload.total,
        ids: uniq([
          ...store.ids,
          ...action.payload.ids,
        ]),
        data: {
          ...store.data,
          ...action.payload.items,
        },
      };
    case NOTIFICATION_REMOVE:
      return {
        ...store,
        total: store.total - 1,
        ids: store.ids.filter((id) => id !== action.payload.id),
      };
    case NOTIFICATION_LIST_CLEAR:
      return initialState;
    default:
      return store;
  }
}

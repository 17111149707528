import axios from 'axios';
import {
  setAuthToken,
  getRefreshToken,
  getAuthToken,
  removeAuthToken,
  removeRefreshToken,
  logout,
} from '../actions/user';
import {
  AUTH_TOKEN_EXPIRED,
  BAD_CREDENTIALS,
  FORBIDDEN_ACCESS,
  ACCOUNT_DISABLED,
} from '../constants';
import { isSSR } from './view';

const clientHeaders = {};

const getHeaders = (headers = {}) => {
  const authToken = getAuthToken();

  return {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    ...(authToken ? {
      'X-Auth-Token': authToken,
    } : {}),
    ...headers,
    ...clientHeaders,
  };
};

export const passClientHeaders = (headers = {}) => {
  Object.assign(clientHeaders, headers);
};

const host = `${process.env.API_URL}`;

const instance = axios.create({
  baseURL: host,
  timeout: 5000,
});

const api = {
  get(url, headers) {
    return instance
      .get(url, {
        headers: getHeaders(headers),
      })
      .then((response) => response.data)
      .catch((error) => this._handleErrors(error, this.get, url));
  },

  post(url, payload, headers) {
    return instance
      .post(url, payload, {
        headers: getHeaders(headers),
      })
      .then((response) => response.data)
      .catch((error) => this._handleErrors(error, this.post, url, payload));
  },

  put(url, payload, headers) {
    return instance
      .put(url, payload, {
        headers: getHeaders(headers),
      })
      .then((response) => response.data)
      .catch((error) => this._handleErrors(error, this.put, url, payload));
  },

  delete(url, headers) {
    return instance
      .delete(url, {
        headers: getHeaders(headers),
      })
      .then((response) => response.data)
      .catch((error) => this._handleErrors(error, this.delete, url));
  },

  head(url, headers) {
    return instance
      .head(url, {
        headers: getHeaders(headers),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => this._handleErrors(error, api.get, url));
  },

  _handleErrors(error, cb, ...params) {
    if (!error.response) {
      return Promise.reject(error);
    }

    const {
      data: body, config: { url }, statusText, status,
    } = error.response;
    const { errorCode } = body;

    if (errorCode === BAD_CREDENTIALS) {
      removeAuthToken();

      if (this._dispatch) {
        this._dispatch(logout());
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        url, status, statusText, body,
      });
    }

    if ([FORBIDDEN_ACCESS, AUTH_TOKEN_EXPIRED, ACCOUNT_DISABLED].includes(errorCode)) {
      removeAuthToken();

      const useRefreshToken = errorCode !== ACCOUNT_DISABLED;

      return this._retryOnFailure(body, cb, params, error.response, useRefreshToken);
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      url, status, statusText, body,
    });
  },

  _retryOnFailure(body, cb, params, response, useRefreshToken = true) {
    const { config: { url }, statusText, status } = response;

    if (!isSSR()) {
      const refreshToken = getRefreshToken();

      if (!refreshToken || !useRefreshToken) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          url, status, statusText, body,
        });
      }

      return instance.post('/auth/token', { refreshToken })
        .then((res) => {
          setAuthToken(res.data.authToken);

          if (!getAuthToken()) {
            return Promise.reject();
          }

          return cb(...params);
        })
        .catch(() => {
          removeRefreshToken();

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({
            url, status, statusText, body,
          });
        });
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      url, status, statusText, body,
    });
  },
};

export const refreshAuthToken = (refreshTokenValue) => {
  return api.post('/auth/token', { refreshToken: refreshTokenValue })
    .then((res) => {
      setAuthToken(res.authToken);
    })
    .catch(() => {
      removeRefreshToken();

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ body: { errorCode: AUTH_TOKEN_EXPIRED } });
    });
};

export const initApi = (dispatch) => {
  api._dispatch = dispatch;
  return api;
};

export default api;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import c from './Menu.less';

export const LINK_TYPES = {
  PROMO: 'promo',
};

function Menu(props) {
  const {
    onItemClick, items, containerClassName, itemClassName, title, listContainerClassName, onItemHover,
  } = props;
  return (
    <nav className={cx(c.menuContainer, containerClassName)} aria-labelledby={title ? 'menu-title' : undefined}>
      {title ? <h3 className={c.title} id="menu-title">{title}</h3> : null}
      <ul className={cx(c.menuList, listContainerClassName)}>
        {
          items.map((item) => (
            <li className={cx(c.menuItem, itemClassName, { [c[item.type]]: item.type })} key={item.key}>
              <NavLink
                exact={item.exact}
                to={item.url}
                activeClassName={c.selected}
                onClick={onItemClick}
                onFocus={onItemHover.bind(this, item)}
                onMouseOver={onItemHover.bind(this, item)}
              >
                {item.title}
              </NavLink>
            </li>
          ))
        }
      </ul>
    </nav>
  );
}

Menu.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  title: PropTypes.string,
  listContainerClassName: PropTypes.string,
  onItemHover: PropTypes.func,
};

Menu.defaultProps = {
  containerClassName: null,
  itemClassName: null,
  title: null,
  listContainerClassName: null,
  onItemHover: () => null,
};

export default Menu;

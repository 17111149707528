export const GENRES_REQUESTED = 'GENRES_REQUESTED';
export const GENRES_SUCCESS = 'GENRES_SUCCESS';
export const GENRES_ERROR = 'GENRES_ERROR';

const initialState = {
  data: {},
  loading: false,
  error: false,
};
export function genres(store = initialState, action) {
  switch (action.type) {
    case GENRES_REQUESTED:
      return {
        ...store,
        loading: true,
        error: false,
      };
    case GENRES_SUCCESS:
      return {
        loading: false,
        error: false,
        data: {
          ...store.data,
          ...action.payload,
        },
      };
    case GENRES_ERROR:
      return {
        data: {},
        loading: false,
        error: true,
      };
    default:
      return store;
  }
}

import CapriceTopLogo from './assets/caprice.png';
import AUTopLogo from './assets/au.png';
import CATopLogo from './assets/ca.png';
import DETopLogo from './assets/de.png';
import UKTopLogo from './assets/uk.png';
import USTopLogo from './assets/us.png';

const chartList = [
  {
    header: 'Caprice TOP',
    title: 'Music Chart',
    description: 'Best music',
    name: 'caprice',
    key: 'Caprice',
    logo: CapriceTopLogo,
  }, {
    header: 'UK TOP',
    title: 'Official UK Music Chart',
    description: 'Official UK Music',
    name: 'uk-top',
    key: 'UK',
    logo: UKTopLogo,
  }, {
    header: 'US TOP',
    title: 'BillBoard Chart',
    description: 'BillBoard',
    name: 'us-top',
    key: 'US',
    logo: USTopLogo,
  }, {
    header: 'DE TOP',
    title: 'MTV Germany Chart',
    description: 'MTV Germany',
    name: 'de-top',
    key: 'DE',
    logo: DETopLogo,
  }, {
    header: 'AU TOP',
    title: 'Aria Chart',
    description: 'Aria Chart',
    name: 'au-top',
    key: 'AU',
    logo: AUTopLogo,
  }, {
    header: 'CA TOP',
    title: 'Canadian Music Chart',
    description: 'Canadian Music Chart',
    name: 'ca-top',
    key: 'CA',
    logo: CATopLogo,
  },
];


export {
  chartList,
};

export default { chartList };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CloseIcon from 'material-design-icons/navigation/svg/production/ic_close_24px.svg';

import MainMenu from '../MainMenu/MainMenu';
import HelpMenu from '../HelpMenu/HelpMenu';
import { siderCollapse } from '../../actions/app';
import Logo from '../Logo';
import { responsive } from '../../helpers/view';
import FacebookShare from '../FacebookShare';

import c from './Sider.less';

function SiderComponent(props) {
  const { app: { siderCollapsed, siderHidden }, siderCollapseAction } = props;

  if (siderHidden) {
    return null;
  }

  const isHelpSection = props.location.pathname.includes('/shop/help');

  const handleCloseSider = () => {
    document.body.classList.remove('disable-scroll');
    siderCollapseAction();
  };

  const handleMenuItemClick = () => {
    handleCloseSider();
  };

  const handleItemHover = (item) => {
    if (item.component && typeof item.component.type.preload === 'function') {
      item.component.type.preload(item.component.props);
    }
  };

  const siderContent = () => {
    if (isHelpSection && !responsive.isMobileOrTablet()) {
      return <HelpMenu onItemClick={handleMenuItemClick} onItemHover={handleItemHover} />;
    }

    return <MainMenu onItemClick={handleMenuItemClick} onItemHover={handleItemHover} />;
  };

  return (
    <div className={classNames(c.container, { [c.visible]: !siderCollapsed, [c.help]: isHelpSection })}>
      <div className={c.sider}>
        <div className={classNames(c.logoWrap, { [c.visible]: !siderCollapsed }, 'hidden-desktop')}>
          <Logo className={c.logo} type="colorful" width={107} height={48} />
          <CloseIcon className={c.closeIcon} onClick={handleCloseSider} />
        </div>
        {siderContent()}
        <FacebookShare />
      </div>
      {
        !siderCollapsed && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <div
            onClick={handleCloseSider}
            onKeyPress={handleCloseSider}
            className={classNames('hidden-desktop', c.backdrop)}
            role="button"
            tabIndex="0"
          />
        )
      }
    </div>
  );
}

SiderComponent.propTypes = {
  app: PropTypes.object.isRequired,
  siderCollapseAction: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  app: store.app,
});

const mapDispatchToProps = {
  siderCollapseAction: siderCollapse,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiderComponent));

import {
  compose, multiply, subtract, flip, defaultTo,
} from 'ramda';

import { listFeedback } from '../../actions/feedback';
import { itemsPerPage } from './common';

const feedbackNeeds = [
  (params) => listFeedback({
    offset: compose(multiply(itemsPerPage), flip(subtract)(1), defaultTo(1))(params.page),
    limit: itemsPerPage,
  }),
];

export default feedbackNeeds;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Layout, BackTop } from 'antd';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { parse } from 'qs';
import { compose } from 'ramda';
import cx from 'classnames';

import { siteDisplayed, clearNotFoundError } from '../../actions/app';
import {
  getRefreshToken, getUser, getBalance, removeAuthToken,
} from '../../actions/user';
import { refreshAuthToken } from '../../helpers/api';
import analytics from '../../helpers/analytics';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sider from '../../components/Sider';
import Favicon from '../../components/Favicon';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import routes from '../../routes';
import Referrer from '../../helpers/referrers';
import SystemMessage from '../../components/SystemMessage';

import c from './App.less';
import { ConnectedMessage } from '../../components/Message/Message';
import GoogleTags from '../../components/GoogleTags/GoogleTags';

const { Content } = Layout;

function _handleReferrer() {
  Referrer.site = document.referrer;
}

class App extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    clearNotFoundError: PropTypes.func.isRequired,
    siteDisplayed: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getBalance: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    staticContext: PropTypes.object,
  };

  static defaultProps = {
    staticContext: null,
  };

  UNSAFE_componentWillMount() {
    this._handleAffiliate();
  }

  componentDidMount() {
    _handleReferrer();

    this.props.siteDisplayed();

    if (this.props.user.loggedIn) {
      analytics.setUserId(this.props.user.data.profile.uuid);
    } else if (getRefreshToken()) {
      removeAuthToken();

      refreshAuthToken(getRefreshToken()).then(() => {
        this.props.getUser();
        this.props.getBalance();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.loggedIn && nextProps.user.loggedIn !== this.props.user.loggedIn) {
      analytics.setUserId(nextProps.user.data.profile.uuid);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    this.props.clearNotFoundError();
  };

  handleHelmetChange = (nextState) => {
    const dpMeta = (nextState.metaTags || []).find((meta) => meta.property === 'mc:dp');
    const pageMeta = (nextState.metaTags || []).find((meta) => meta.property === 'mc:page');

    if (dpMeta) {
      if (this.pageTitle !== nextState.title || this.pageDp !== dpMeta.content) {
        analytics.pageview(dpMeta.content, { title: nextState.title });

        if (pageMeta) {
          analytics.setActivePage(pageMeta.content);
        }

        this.pageTitle = nextState.title;
        this.pageDp = dpMeta.content;
      }
    }
  };

  _handleAffiliate() {
    const { pid, subid } = parse(this.props.location.search.slice(1));

    if (pid) {
      Referrer.pid = pid;
      Referrer.subId = subid;
    }
  }

  render() {
    const { app: { misc: { errorMsg } } } = this.props;

    return (
      <div className={c.appContainer}>
        <Helmet defer={false} onChangeClientState={this.handleHelmetChange}>
          <title>Mp3Caprice - High Quality Music Downloads</title>
          <meta name="description" content="Discover, buy and download high quality tunes for reasonable prices." />
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;700&display=swap" type="text/css" />
        </Helmet>
        <GoogleTags />
        <Favicon />
        <Header />
        <SystemMessage />
        <ConnectedMessage />
        <div className={cx(c.container, { [c.hasErrorMsg]: !!errorMsg })} id="app-container">
          <Sider />
          <Layout className={c.contentWrap}>
            <Content className={c.contentContainer}>
              <ErrorBoundary>
                <Switch>
                  {
                    routes.map((route) => {
                      if (route.to) {
                        return (
                          <Route
                            exact
                            key={route.path}
                            path={route.path}
                            render={(routeProps) => {
                              const { staticContext } = routeProps;
                              if (staticContext) staticContext.page.status = 301;

                              if (typeof route.to === 'function') {
                                return <Redirect to={route.to(routeProps)} />;
                              }

                              return <Redirect exact key={route.path} {...route} />;
                            }}
                          />
                        );
                      }

                      return (
                        <Route
                          {...route}
                          key={route.path}
                          render={({ staticContext, location }) => {
                            if (route.name === 'NotFound' && staticContext) {
                              staticContext.page.status = 404;
                            }

                            if (route.unauthenticatedOnly && this.props.user.loggedIn) {
                              if (staticContext) staticContext.page.status = 302;
                              const to = (location.search.length > 0 && parse(location.search.slice(1)).to) || '/shop/profile';
                              return <Redirect key={route.path} to={to} />;
                            }

                            if (route.requireAuthentication && !this.props.user.loggedIn) {
                              if (staticContext) staticContext.page.status = 302;
                              const redirectAfterLogin = location.pathname;
                              return <Redirect key={route.path} to={`/shop/login?to=${redirectAfterLogin}`} />;
                            }

                            return (route.routeComponent);
                          }}
                        />
                      );
                    })
                  }
                </Switch>
              </ErrorBoundary>
            </Content>
          </Layout>
        </div>
        <BackTop />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  app: store.app,
});

const mapDispatchToProps = {
  siteDisplayed,
  clearNotFoundError,
  getUser,
  getBalance,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(App);

import { normalize } from 'normalizr';
import {
  uniq,
  mergeDeepLeft,
  union,
} from 'ramda';
import { push } from 'connected-react-router';
import { trackSchema, releaseSchema, releaseListSchema } from '../schemas';

import {
  SC_SUMMARY_REQUESTED,
  SC_SUMMARY_SUCCESS,
  SC_ADD_TRACK_REQUESTED,
  SC_ADD_TRACK_SUCCESS,
  SC_REMOVE_TRACK_REQUESTED,
  SC_REMOVE_TRACK_SUCCESS,
  SC_LIST_REQUESTED,
  SC_LIST_SUCCESS,
  SC_BUY_TRACKS_REQUESTED,
  SC_BUY_TRACKS_SUCCESS,
  SC_BUY_TRACKS_FAIL,
  SC_REMOVE_RELEASE_SUCCESS,
} from '../reducers/sc';
import { ARTIST_SUCCESS } from '../reducers/artist';
import { RELEASES_SUCCESS } from '../reducers/releases';
import { TRACK_SUCCESS } from '../reducers/track';

export const loadCartSummary = () => {
  return (dispatch, _, api) => {
    dispatch({ type: SC_SUMMARY_REQUESTED });

    return api.get('/sc/summary')
      .then((response) => {
        dispatch({
          type: SC_SUMMARY_SUCCESS,
          payload: response,
        });
      });
  };
};

export const addTrackToCart = (trackIds) => {
  return (dispatch, _, api) => {
    dispatch({ type: SC_ADD_TRACK_REQUESTED });

    return api.post('/sc/track', {
      id: Array.isArray(trackIds) ? trackIds : [trackIds],
    })
      .then((response) => {
        const data = normalize(response, trackSchema);

        dispatch([{
          type: SC_ADD_TRACK_SUCCESS,
          payload: response,
        }, {
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }]);
      })
      .catch((err) => {
        if (err.body.errorCode === 'SHOPPING_CART_IS_FULL') {
          dispatch(push('/shop/checkout'));
        }

        return Promise.reject(err);
      });
  };
};

export const removeTracksFromCart = (trackIds) => {
  const normalizedTrackIds = Array.isArray(trackIds) ? trackIds : [trackIds];

  return (dispatch, getState, api) => {
    dispatch({ type: SC_REMOVE_TRACK_REQUESTED });

    return api.delete(`/sc/track/${normalizedTrackIds.join(',')}`)
      .then((response) => {
        const data = normalize(response, trackSchema);
        const state = getState();
        const removedReleases = [];
        const nextItems = {};
        let nextOrderedIds = state.shoppingCart.cart.order;

        Object.keys(state.shoppingCart.cart.data)
          .forEach((releaseId) => {
            const releaseTracks = state.shoppingCart.cart.data[releaseId]
              .filter((trackId) => !normalizedTrackIds.includes(trackId));

            if (releaseTracks.length > 0) {
              nextItems[releaseId] = releaseTracks;
            } else {
              nextOrderedIds = nextOrderedIds.filter((id) => id !== Number(releaseId));
              removedReleases.push(releaseId);
            }
          });

        dispatch([{
          type: SC_REMOVE_TRACK_SUCCESS,
          payload: {
            items: nextItems,
            total: state.shoppingCart.cart.total - removedReleases.length,
            order: nextOrderedIds,
          },
        }, {
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }]);
      });
  };
};

export const addReleaseToCart = (releaseId) => {
  return (dispatch, _, api) => {
    return api.post('/sc/release', {
      id: [releaseId],
    })
      .then((response) => {
        const data = normalize(response, releaseSchema);

        dispatch({
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        });
      })
      .catch((err) => {
        if (err.body.errorCode === 'SHOPPING_CART_IS_FULL') {
          dispatch(push('/shop/checkout'));
        }

        return Promise.reject(err);
      });
  };
};

export const removeReleaseFromCart = (releaseId) => {
  return (dispatch, _, api) => {
    return api.delete(`/sc/release/${releaseId}`)
      .then((response) => {
        const data = normalize(response, releaseSchema);

        dispatch([{
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }, {
          type: SC_REMOVE_RELEASE_SUCCESS,
          payload: {
            releaseId,
          },
        }]);
      });
  };
};

export const listCart = ({ offset = 0, limit = 50, clearBeforeLoading = true } = {
  offset: 0,
  limit: 50,
  clearBeforeLoading: true,
}) => (dispatch, getState, api) => {
  dispatch({ type: SC_LIST_REQUESTED });

  return api.get(`/sc?limit=${limit}&offset=${offset}`)
    .then((response) => {
      const data = normalize(response.items, releaseListSchema);
      const { shoppingCart: { cart } } = getState();
      const scList = data.entities.release ? Object.keys(data.entities.release).reduce((acc, releaseId) => {
        acc[releaseId] = data.entities.release[releaseId].tracks;
        return acc;
      }, {}) : {};

      dispatch([{
        type: RELEASES_SUCCESS,
        payload: data.entities.release,
      }, {
        type: ARTIST_SUCCESS,
        payload: data.entities.artist,
      }, {
        type: TRACK_SUCCESS,
        payload: data.entities.track,
      }, {
        type: SC_LIST_SUCCESS,
        payload: {
          items: clearBeforeLoading ? scList : mergeDeepLeft(cart.data, scList),
          total: clearBeforeLoading ? response.total : Math.max(response.total, cart.total),
          order: clearBeforeLoading ? data.result : union(cart.order, data.result),
        },
      }]);
    });
};

export const buyTracks = (trackIds) => (dispatch, getState, api) => {
  dispatch({ type: SC_BUY_TRACKS_REQUESTED });

  return api.post('/purchase', {
    id: trackIds,
  }).then(() => {
    const state = getState();

    dispatch([{
      type: SC_BUY_TRACKS_SUCCESS,
      payload: uniq(state.shoppingCart.purchase.tracks.concat(trackIds)),
    }]);
  }).catch((err) => {
    dispatch({ type: SC_BUY_TRACKS_FAIL, payload: err });
  });
};

export default {
  loadCartSummary,
  addTrackToCart,
  removeTracksFromCart,
  addReleaseToCart,
  removeReleaseFromCart,
  listCart,
  buyTracks,
};

import { normalize } from 'normalizr';
import {
  map,
  mergeDeepLeft, propOr, union,
} from 'ramda';
import { stringify } from 'qs';

import api from 'helpers/api';
import analytics from 'helpers/analytics';
import { releaseSchema, releaseListSchema } from 'schemas';
import {
  RELEASES_REQUESTED,
  RELEASES_SUCCESS,
  RELEASES_FAIL,
  HOME_LAST_ADDED_RELEASES_SUCCESS,
  LAST_ADDED_RELEASES_SUCCESS,
  PROMO_RELEASES_SUCCESS,
  ARTIST_RELEASES_SUCCESS,
  GENRE_RELEASES_SUCCESS,
  SERIES_RELEASES_SUCCESS,
  CHART_RELEASES_SUCCESS,
  ARTIST_MORE_RELEASES_SUCCESS,
  RECOMMENDED_RELEASES_SUCCESS,
  PURCHASED_RELEASES_SUCCESS,
  PURCHASED_RELEASES_REQUESTED, PURCHASED_RELEASES_FAILED, HOME_RECOMMENDED_RELEASES_SUCCESS,
} from 'reducers/releases';
import { ARTIST_SUCCESS } from 'reducers/artist';
import { TRACK_SUCCESS } from 'reducers/track';
import { SERIES_SUCCESS } from 'reducers/series';
import { GENRES_SUCCESS } from 'reducers/genre';

export function releaseById(id) {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });

    return api.get(`/releases/${id}`)
      .then((response) => {
        const data = normalize(response, releaseSchema);

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }]);
      })
      .catch((err) => {
        dispatch({ type: RELEASES_FAIL });

        throw err;
      });
  };
}

export function releasesByArtist(id, releaseTypes = 'Album', { limit = 9, offset = 0 } = {}) {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });

    let releaseTypesParam = releaseTypes;

    if (releaseTypes && !Array.isArray(releaseTypes)) {
      releaseTypesParam = [releaseTypesParam];
    }

    const queryString = stringify({
      artist: id,
      limit,
      offset,
      releaseTypes: releaseTypesParam && releaseTypesParam.join(','),
    });

    return api.get(`/releases?${queryString}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: ARTIST_RELEASES_SUCCESS,
          payload: {
            artistId: id,
            total: response.total,
            releaseList: data.result,
            type: releaseTypesParam.join(','),
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

export function getMoreReleasesByArtist(artistId, releaseId, { limit = 6, offset = 0 } = { limit: 6, offset: 0 }) {
  return (dispatch, getState) => {
    dispatch({ type: RELEASES_REQUESTED });

    const queryString = stringify({
      artist: artistId,
      limit,
      offset,
      releaseTypes: 'Album,Single,Remix,Live',
      excludeReleaseIds: releaseId,
    });

    return api.get(`/releases?${queryString}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);

        const state = getState();

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: ARTIST_MORE_RELEASES_SUCCESS,
          payload: {
            artistId,
            total: response.total,
            releaseList: union(propOr([], artistId, state.releases.moreByArtist.ids), data.result),
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

export function releasesBySeries(id, { limit = 9, offset = 0, concat = false } = {}) {
  return (dispatch, getState) => {
    dispatch({ type: RELEASES_REQUESTED });
    return api.get(`/releases?series=${id}&limit=${limit}&offset=${offset}`)
      .then((response) => {
        const data = normalize(
          response.items.map((release) => ({ ...release, series: Number(id) })),
          releaseListSchema
        );

        const state = getState();

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: SERIES_RELEASES_SUCCESS,
          payload: {
            seriesId: id,
            total: response.total,
            releaseList: concat ? union(propOr([], id, state.releases.bySeries.ids), data.result) : data.result,
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

export function releasesByGenre(id, { limit = 9, offset = 0, concat = false } = {}) {
  return (dispatch, getState) => {
    dispatch({ type: RELEASES_REQUESTED });

    return api.get(`/releases?genre=${id}&limit=${limit}&offset=${offset}`)
      .then((response) => {
        const state = getState();

        const data = normalize(
          response.items,
          releaseListSchema
        );
        dispatch([{
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: GENRE_RELEASES_SUCCESS,
          payload: {
            total: response.total,
            genreId: id,
            releaseList: concat ? union(propOr([], id, state.releases.byGenre.ids), data.result) : data.result,
          },
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

const genId = () => Math.random().toString(36).substr(2, 9);

export function releasesByChart(chartType, { limit = 20, offset = 0 } = {}) {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });
    return api.get(`/releases?chart=${chartType}&limit=${limit}&offset=${offset}`)
      .then((response) => {
        response.items = map((item) => {
          let newItem = item;

          if (item.id === -1) {
            newItem = mergeDeepLeft({
              id: genId(),
              absent: true,
            }, newItem);
          }

          if (item.artist.id === -1) {
            newItem = mergeDeepLeft({
              artist: {
                id: genId(),
                absent: true,
              },
            }, newItem);
          }

          return newItem;
        }, response.items);

        const data = normalize(
          response.items,
          releaseListSchema
        );

        dispatch([{
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: CHART_RELEASES_SUCCESS,
          payload: {
            total: response.total,
            chartType,
            releaseList: data.result,
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

export function getLastAdded({
  limit = 9, offset = 0, date, concat = false,
} = {}) {
  return (dispatch, getState) => {
    dispatch({ type: RELEASES_REQUESTED });

    const searchQuery = {
      type: 'lastAdded',
      limit,
      offset,
      ...(date ? {
        addedDate: date,
      } : {}),
    };

    return api.get(`/releases?${stringify(searchQuery)}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);
        const actions = [{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }];

        if (data.result.length > 0) {
          const state = getState();

          actions.push({
            type: LAST_ADDED_RELEASES_SUCCESS,
            payload: {
              total: response.total,
              date: date || response.items[0].addedDate,
              releaseList: concat ? union(propOr([], date, state.releases.byDate.ids), data.result) : data.result,
            },
          });
        }

        dispatch(actions);
      });
  };
}

export function getHomeLastAdded({ limit = 9, offset = 0 } = {}) {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });

    const searchQuery = {
      type: 'lastAdded',
      limit,
      offset,
    };

    return api.get(`/releases?${stringify(searchQuery)}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);

        const actions = [{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }];

        if (data.result.length > 0) {
          actions.push({
            type: HOME_LAST_ADDED_RELEASES_SUCCESS,
            payload: {
              total: response.total,
              ids: data.result,
            },
          });
        }

        dispatch(actions);
      });
    // .catch((err) => {
    //   handleApiErrors(err, dispatch);
    // });
  };
}

export function getHomeRecommended() {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });

    const searchQuery = {
      cid: analytics.getUUID(),
    };

    return api.get(`/recommendations/index-page/releases?${stringify(searchQuery)}`)
      .then((response) => {
        const data = normalize(response, releaseListSchema);

        const actions = [{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }];

        if (data.result.length > 0) {
          actions.push({
            type: HOME_RECOMMENDED_RELEASES_SUCCESS,
            payload: data.result,
          });
        }

        dispatch(actions);
      });
  };
}

export function getPromo(limit = 2, offset = 0) {
  return (dispatch) => {
    dispatch({ type: RELEASES_REQUESTED });

    return api.get(`/releases?chart=Promo&limit=${limit}&offset=${offset}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);
        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: PROMO_RELEASES_SUCCESS,
          payload: data.result,
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);
      });
  };
}

export function getRecommendedReleases({
  releaseId, offset = 0, limit = 6, append = false,
} = {
  offset: 0,
  limit: 6,
  append: false,
}) {
  return (dispatch, getState) => {
    dispatch({ type: RELEASES_REQUESTED });

    return api.get(`/recommendations/release-by-release/releases/${releaseId}?offset=${offset}&limit=${limit}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);
        const { releases: { recommended } } = getState();

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: RECOMMENDED_RELEASES_SUCCESS,
          payload: {
            ids: append ? union(recommended.data[releaseId], data.result) : data.result,
            total: response.total,
            releaseId,
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }]);
      });
  };
}

export function getUserRecommendations(offset = 0, limit = 6) {
  return api.get(`/recommendations/user-based/releases?limit=${limit}&offset=${offset}`);
}

export function getReleaseDates(month) {
  return () => api.get(`/release-calendar?month=${month}`);
}

export function getPurchasedReleases({
  limit = 5, offset = 0, searchQuery = null, loadMore = false,
} = {
  limit: 5,
  offset: 0,
  searchQuery: null,
  loadMore: false,
}) {
  return (dispatch, getState) => {
    dispatch({ type: PURCHASED_RELEASES_REQUESTED });

    let queryObj = {
      limit,
      offset,
    };
    let path = '/dwl';

    if (searchQuery) {
      queryObj = {
        ...queryObj,
        query: searchQuery,
        type: 'AC',
      };
      path = '/dwl/search';
    }

    return api.get(`${path}?${stringify(queryObj)}`)
      .then((response) => {
        const data = normalize(response.items, releaseListSchema);
        const { releases: { purchased: purchasedReleases } } = getState();

        dispatch([{
          type: RELEASES_SUCCESS,
          payload: data.entities.release,
        }, {
          type: ARTIST_SUCCESS,
          payload: data.entities.artist,
        }, {
          type: TRACK_SUCCESS,
          payload: data.entities.track,
        }, {
          type: PURCHASED_RELEASES_SUCCESS,
          payload: {
            ids: loadMore ? union(purchasedReleases.data, data.result) : data.result,
            total: response.total,
          },
        }, {
          type: GENRES_SUCCESS,
          payload: data.entities.genre,
        }, {
          type: SERIES_SUCCESS,
          payload: data.entities.series,
        }]);

        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({
          type: PURCHASED_RELEASES_FAILED,
          payload: {
            error: err,
          },
        });

        return Promise.reject(err);
      });
  };
}

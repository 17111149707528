import { combineReducers } from 'redux';
import { mergeDeepLeft, clone, without } from 'ramda';

export const SC_SUMMARY_REQUESTED = 'SC_SUMMARY_REQUESTED';
export const SC_SUMMARY_SUCCESS = 'SC_SUMMARY_SUCCESS';
export const SC_ADD_TRACK_SUCCESS = 'SC_ADD_TRACK_SUCCESS';
export const SC_ADD_TRACK_REQUESTED = 'SC_ADD_TRACK_REQUESTED';
export const SC_REMOVE_TRACK_REQUESTED = 'SC_REMOVE_TRACK_REQUESTED';
export const SC_REMOVE_TRACK_SUCCESS = 'SC_REMOVE_TRACK_SUCCESS';
export const SC_REMOVE_RELEASE_SUCCESS = 'SC_REMOVE_RELEASE_SUCCESS';
export const SC_LIST_REQUESTED = 'SC_LIST_REQUESTED';
export const SC_LIST_SUCCESS = 'SC_LIST_SUCCESS';

export const SC_BUY_TRACKS_REQUESTED = 'SC_BUY_TRACKS_REQUESTED';
export const SC_BUY_TRACKS_SUCCESS = 'SC_BUY_TRACKS_SUCCESS';
export const SC_BUY_TRACKS_FAIL = 'SC_BUY_TRACKS_FAIL';
export const SC_BUY_TRACKS_CLEAN = 'SC_BUY_TRACKS_CLEAN';

const initialSummaryState = {
  data: {},
  loading: true,
};

export function summary(store = initialSummaryState, action) {
  switch (action.type) {
    case SC_SUMMARY_REQUESTED:
      return mergeDeepLeft({
        loading: true,
      }, store);
    case SC_SUMMARY_SUCCESS: {
      return mergeDeepLeft({
        loading: false,
        data: action.payload,
      }, store);
    }
    default:
      return store;
  }
}


const initialCartState = {
  data: {},
  order: [],
  total: 0,
  loading: true,
};

export function cart(store = initialCartState, { type, payload }) {
  switch (type) {
    case SC_LIST_REQUESTED:
      return mergeDeepLeft({
        loading: true,
      }, store);
    case SC_LIST_SUCCESS: {
      return {
        loading: false,
        total: payload.total,
        data: payload.items,
        order: payload.order,
      };
    }
    case SC_REMOVE_TRACK_SUCCESS: {
      return {
        loading: false,
        total: payload.total,
        data: payload.items,
        order: payload.order,
      };
    }
    case SC_REMOVE_RELEASE_SUCCESS: {
      const numReleaseId = Number(payload.releaseId);
      const nextData = clone(store.data);
      let nextOrder = clone(store.order);
      let nextTotal = store.total;

      delete nextData[numReleaseId];
      nextTotal -= 1;
      nextOrder = without([numReleaseId], nextOrder);

      return {
        loading: false,
        total: nextTotal,
        data: nextData,
        order: nextOrder,
      };
    }
    default:
      return store;
  }
}

const initialPurchaseState = {
  loading: false,
  error: null,
  success: false,
  tracks: [],
};

export function purchase(store = initialPurchaseState, action) {
  switch (action.type) {
    case SC_BUY_TRACKS_REQUESTED:
      return mergeDeepLeft({
        loading: true,
      }, store);
    case SC_BUY_TRACKS_SUCCESS: {
      return mergeDeepLeft({
        loading: false,
        success: true,
        tracks: action.payload,
      }, store);
    }
    case SC_BUY_TRACKS_FAIL: {
      return mergeDeepLeft({
        loading: false,
        error: action.payload,
      }, store);
    }
    case SC_BUY_TRACKS_CLEAN: {
      return mergeDeepLeft({
        error: null,
        success: false,
        tracks: [],
      }, store);
    }
    default:
      return store;
  }
}

export default combineReducers({
  summary,
  cart,
  purchase,
});

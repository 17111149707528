import React from 'react';
import PropTypes from 'prop-types';

import Logo from './assets/logo.png';
import Logo2x from './assets/logo@2x.png';
import Logo3x from './assets/logo@3x.png';

import LogoColorful from './assets/colorful-inv.png';
import LogoColorful2x from './assets/colorful-inv@2x.png';
import LogoColorful3x from './assets/colorful-inv@3x.png';

const LogoComponent = (props) => {
  const {
    className, type, width, height,
  } = props;
  let srcLogo = Logo;
  let srcSetLogo = `${Logo2x} 2x, ${Logo3x} 3x`;

  if (type === 'colorful') {
    srcLogo = LogoColorful;
    srcSetLogo = `${LogoColorful2x} 2x, ${LogoColorful3x} 3x`;
  }

  return (
    <img className={className} alt="Mp3Caprice Logo" src={srcLogo} srcSet={srcSetLogo} width={width} height={height} />
  );
};

LogoComponent.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

LogoComponent.defaultProps = {
  className: '',
  type: 'mono',
};

export default LogoComponent;

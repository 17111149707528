import { artistById } from '../../actions/artist';
import { releasesByArtist } from '../../actions/release';
import { tracksByArtist } from '../../actions/track';
import { defaultPagination, releaseBlocks } from './common';

const artistNeeds = [
  (params) => artistById(params.id),
  (params) => tracksByArtist(params.id, undefined, undefined, true),
  ...(
    releaseBlocks
      .map((releaseBlock) => (params, state) => {
        if (state.artistList.data[params.id]) {
          return releasesByArtist(params.id, releaseBlock.types, {
            limit: defaultPagination.maxCols,
          });
        }
        return { type: '' };
      })
  ),
];

export default artistNeeds;

export {
  artistNeeds,
};

import { stringify } from 'qs';
import { normalize } from 'normalizr';
import { isEmpty, uniq } from 'ramda';

import api from '../helpers/api';
import {
  SEARCH_REQUESTED,
  SEARCH_SUCCESS,
  SEARCH_CLEAR,
  SEARCH_CLEAR_ARTISTS,
  SEARCH_CLEAR_RELEASES,
  SEARCH_CLEAR_TRACKS,
  SEARCH_SUCCESS_RELEASES,
  SEARCH_SUCCESS_ARTISTS,
  SEARCH_SUCCESS_GENRES,
  SEARCH_SUCCESS_SERIES,
  SEARCH_SUCCESS_TRACKS,
} from '../reducers/search';
import {
  trackListSchema, releaseListSchema, artistListSchema, genreListSchema, seriesListSchema,
} from '../schemas';
import { ARTIST_SUCCESS } from '../reducers/artist';
import { TRACK_SUCCESS } from '../reducers/track';
import { RELEASES_SUCCESS } from '../reducers/releases';
import { GENRES_SUCCESS } from '../reducers/genre';
import { SERIES_SUCCESS } from '../reducers/series';

export function autocomplete(query, type = null, { offset, limit } = { offset: 0, limit: 6 }, clearBeforeLoading) {
  return (dispatch, getState) => {
    dispatch({ type: SEARCH_REQUESTED });

    const queryObj = {
      query,
      offset,
      limit,
      type: type ? `AC_${type.toUpperCase()}` : 'AC',
    };

    const shouldConcatResults = type !== null && !clearBeforeLoading;

    return api.get(`/search?${stringify(queryObj)}`)
      .then((response) => {
        const store = getState();
        const actions = [];
        const artistsPayload = {};

        if (response.tracks) {
          const tracksData = normalize(response.tracks.items, trackListSchema);

          actions.push({
            type: TRACK_SUCCESS,
            payload: tracksData.entities.track,
          });

          actions.push({
            type: SEARCH_SUCCESS_TRACKS,
            payload: {
              total: response.tracks.total,
              items: shouldConcatResults
                ? uniq(store.search.data.tracks.items.concat(tracksData.result))
                : tracksData.result,
            },
          });

          Object.assign(artistsPayload, tracksData.entities.artist);
        } else {
          actions.push({
            type: SEARCH_CLEAR_TRACKS,
          });
        }

        if (response.releases) {
          const releaseData = normalize(response.releases.items, releaseListSchema);

          actions.push({
            type: RELEASES_SUCCESS,
            payload: releaseData.entities.release,
          });

          actions.push({
            type: SEARCH_SUCCESS_RELEASES,
            payload: {
              total: response.releases.total,
              items: shouldConcatResults
                ? uniq(store.search.data.releases.items.concat(releaseData.result))
                : releaseData.result,
            },
          });

          Object.assign(artistsPayload, releaseData.entities.artist);
        } else {
          actions.push({
            type: SEARCH_CLEAR_RELEASES,
          });
        }

        if (response.artists) {
          const artistsData = normalize(response.artists.items, artistListSchema);

          Object.assign(artistsPayload, artistsData.entities.artist);

          actions.push({
            type: SEARCH_SUCCESS_ARTISTS,
            payload: {
              total: response.artists.total,
              items: shouldConcatResults
                ? uniq(store.search.data.artists.items.concat(artistsData.result))
                : artistsData.result,
            },
          });
        } else {
          actions.push({
            type: SEARCH_CLEAR_ARTISTS,
          });
        }

        if (response.genres) {
          const genresData = normalize(response.genres.items, genreListSchema);

          actions.push({
            type: GENRES_SUCCESS,
            payload: genresData.entities.genre,
          });

          actions.push({
            type: SEARCH_SUCCESS_GENRES,
            payload: {
              total: response.genres.total,
              items: shouldConcatResults
                ? uniq(store.search.data.genres.items.concat(genresData.result))
                : genresData.result,
            },
          });
        }

        if (response.series) {
          const seriesData = normalize(response.series.items, seriesListSchema);

          actions.push({
            type: SERIES_SUCCESS,
            payload: seriesData.entities.series,
          });

          actions.push({
            type: SEARCH_SUCCESS_SERIES,
            payload: {
              total: response.series.total,
              items: shouldConcatResults
                ? uniq(store.search.data.series.items.concat(seriesData.result))
                : seriesData.result,
            },
          });
        }

        if (!isEmpty(artistsPayload)) {
          actions.push({
            type: ARTIST_SUCCESS,
            payload: artistsPayload,
          });
        }

        dispatch([
          ...actions,
          {
            type: SEARCH_SUCCESS,
          },
        ]);
      });
  };
}

export function clearAutocomplete() {
  return (dispatch) => dispatch({ type: SEARCH_CLEAR });
}

export default {
  autocomplete,
};

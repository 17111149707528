import { stringify } from 'qs';

import api from '../helpers/api';
import {
  MUSIC_REQUEST_LIST_REQUESTED,
  MUSIC_REQUEST_LIST_SUCCESS,
} from '../reducers/musicRequests';

export function listRequests({
  offset = 0, limit = 10, state, append = false,
} = {
  offset: 0, limit: 10, state: null, append: false,
}) {
  return (dispatch, getState) => {
    dispatch({ type: MUSIC_REQUEST_LIST_REQUESTED });

    const queryObj = {
      offset,
      limit,
      state,
    };

    return api.get(`/music-requests?${stringify(queryObj)}`)
      .then((response) => {
        let musicRequestList = response.items;

        if (append) {
          musicRequestList = getState().musicRequests.data.concat(musicRequestList);
        }

        dispatch({
          type: MUSIC_REQUEST_LIST_SUCCESS,
          payload: {
            items: musicRequestList,
            total: response.total,
          },
        });
      });
  };
}

export default {
  listRequests,
};

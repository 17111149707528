import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function Balance({ value }) {
  return (
    <Fragment>
      {value < 0 ? '-' : ''}$<strong>{Math.abs(value).toFixed(2)}</strong>
    </Fragment>
  );
}

Balance.propTypes = {
  value: PropTypes.number,
};

Balance.defaultProps = {
  value: 0,
};

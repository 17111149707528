import React from 'react';
import Helmet from 'react-helmet';
import { isSSR } from '../../helpers/view';

function GoogleTags() {
  return isSSR() ? (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_AW_CONVERSION_ID}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [ ] ;
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date () ) ;
          gtag('config', '${process.env.GA_AW_CONVERSION_ID}', {'send_page_view': false});
        `}
      </script>
    </Helmet>
  ) : null;
}

export default GoogleTags;

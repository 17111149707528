import { combineReducers } from 'redux';

export const REVIEWS_REQUESTED = 'REVIEWS_REQUESTED';
export const REVIEWS_SUCCESS = 'REVIEWS_SUCCESS';

const initialStore = {
  loading: false,
  error: false,
  data: {},
};

export function list(store = initialStore, action) {
  switch (action.type) {
    case REVIEWS_REQUESTED:
      return {
        ...store,
        loading: true,
      };
    case REVIEWS_SUCCESS:
      return {
        loading: false,
        error: false,
        data: {
          ...store.data,
          ...action.payload,
        },
      };
    default:
      return store;
  }
}

export const RELEASE_REVIEWS_SUCCESS = 'RELEASE_REVIEWS_SUCCESS';

export function byRelease(store = { total: {}, ids: {} }, action) {
  switch (action.type) {
    case RELEASE_REVIEWS_SUCCESS: {
      const { releaseId, reviewList, total } = action.payload;
      return {
        total: {
          ...store.total,
          [releaseId]: total,
        },
        ids: {
          ...store.ids,
          [releaseId]: reviewList,
        },
      };
    }
    default:
      return store;
  }
}

export default combineReducers({
  list,
  byRelease,
});

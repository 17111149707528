import cookies from 'react-cookies';
import uuid from 'uuid';
import { stringify } from 'qs';

import api from './api';

const GA_COOKIE_NAME = '_ga';
const cookieDomain = `.${process.env.SITE_DOMAIN}`;

export default {
  userId: null,
  activePage: null,
  getUUID() {
    let userId = cookies.load(GA_COOKIE_NAME);

    if (!userId) {
      userId = uuid.v4();
      cookies.save(GA_COOKIE_NAME, uuid.v4(), {
        path: '/',
        domain: cookieDomain,
        maxAge: 315360000, // 10 years
      });
    }

    return userId;
  },
  getPID() {
    return cookies.load('pid');
  },
  getSubID() {
    return cookies.load('subid');
  },
  trackEvent() {
    // stab
    return this;
  },
  setUserId(id) {
    this.userId = id;
    return this;
  },
  clearUserId() {
    this.userId = null;
    return this;
  },
  setActivePage(page) {
    this.activePage = page;
    return this;
  },
  pageview(page, { title = document.title } = { title: document.title }) {
    setTimeout(() => {
      const payload = {
        dp: page,
        dt: title,
        dh: window.location.origin,
        dr: document.referrer,
        uid: this.userId,
        cid: this.getUUID(),
        cd3: this.getPID(), // custom dimension 3
        cd4: this.getSubID(), // custom dimension 4
        sr: `${window.screen.width}x${window.screen.height}`,
        vp: `${window.screen.availWidth}x${window.screen.availHeight}`,
        sd: `${window.screen.colorDepth}-bits`,
        ul: (navigator.language || navigator.userLanguage).toLowerCase(),
        ua: navigator.userAgent,
        vtz: new Date().toString().match(/([A-Z]+[+-][0-9]+)/)[1],
      };

      api
        .get(`/collect?${stringify(payload)}`)
        .catch(() => {
          // that's okay
        });
    }, 0);

    return this;
  },
};

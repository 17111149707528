import api from '../helpers/api';
import { setAuthToken, removeRefreshToken } from './user';
import {
  LOGIN_SUCCESS, USER_SUCCESS, ACCOUNT_ACTIVATION_SUCCESS, ACCOUNT_ACTIVATION_FAIL,
} from '../reducers/user';
import { TOKEN_VALID, TOKEN_INVALID } from '../reducers/reset-password';

export function requestResetPasswordLink(fields, captcha) {
  return () => api.post('/account/password/reset', fields, {
    'g-recaptcha-response': captcha,
  });
}

export function resetPassword(fields, token = null) {
  return (dispatch) => api.put('/account/password/reset', fields, (token ? { 'X-Auth-Token': token } : {}))
    .then((response) => {
      setAuthToken(response.authToken, false);
      removeRefreshToken();

      dispatch([
        { type: LOGIN_SUCCESS },
        {
          type: USER_SUCCESS,
          payload: {
            profile: {
              username: response.username,
            },
          },
        }]);
    });
}

export function validateToken(token) {
  return (dispatch) => api.head('/account', (token ? { 'X-Auth-Token': token } : {}))
    .then(() => {
      dispatch({ type: TOKEN_VALID });
    })
    .catch(() => {
      dispatch({ type: TOKEN_INVALID });
    });
}

export function requestActivateAccountLink() {
  return () => api.post('/account/profile/verify-email');
}

export function activateAccount(token) {
  return (dispatch) => api.put('/account/profile/verify-email', {}, (token ? { 'X-Auth-Token': token } : {}))
    .then(() => {
      dispatch({
        type: ACCOUNT_ACTIVATION_SUCCESS,
      });

      return true;
    })
    .catch((err) => {
      let error = '';

      if ([401, 403].includes(err.status)) {
        error = 'Invalid or expired token.';
      }

      dispatch({ type: ACCOUNT_ACTIVATION_FAIL, payload: error });

      return true;
    });
}

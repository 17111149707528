export const RELEASE_DATES_REQUESTED = 'RELEASE_DATES_REQUESTED';
export const RELEASE_DATES_SUCCESS = 'RELEASE_DATES_SUCCESS';

const initialStore = {
  loading: false,
  error: false,
  dates: [],
};

export default function (store = initialStore, { type, payload }) {
  switch (type) {
    case RELEASE_DATES_REQUESTED:
      return {
        ...store,
        loading: true,
      };
    case RELEASE_DATES_SUCCESS:
      return {
        loading: false,
        dates: payload,
      };
    default:
      return store;
  }
}

import { parse } from 'qs';
import { activateAccount } from '../../actions/account';

export default [
  (params, state, match, search) => {
    const { token } = parse(search);

    return activateAccount(token);
  },
];
